/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var faculty_pb = require('./faculty_pb.js')
const proto = {};
proto.pbs = require('./major_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.MajorServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.MajorServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Majors>}
 */
const methodDescriptor_MajorService_List = new grpc.web.MethodDescriptor(
  '/pbs.MajorService/List',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.Majors,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Majors.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Majors>}
 */
const methodInfo_MajorService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Majors,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Majors.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Majors)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Majors>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.MajorServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.MajorService/List',
      request,
      metadata || {},
      methodDescriptor_MajorService_List,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Majors>}
 *     A native promise that resolves to the response
 */
proto.pbs.MajorServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.MajorService/List',
      request,
      metadata || {},
      methodDescriptor_MajorService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Major,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_MajorService_Create = new grpc.web.MethodDescriptor(
  '/pbs.MajorService/Create',
  grpc.web.MethodType.UNARY,
  proto.pbs.Major,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Major} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Major,
 *   !proto.pbs.Empty>}
 */
const methodInfo_MajorService_Create = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Major} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Major} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.MajorServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.MajorService/Create',
      request,
      metadata || {},
      methodDescriptor_MajorService_Create,
      callback);
};


/**
 * @param {!proto.pbs.Major} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.MajorServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.MajorService/Create',
      request,
      metadata || {},
      methodDescriptor_MajorService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Major,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_MajorService_Delete = new grpc.web.MethodDescriptor(
  '/pbs.MajorService/Delete',
  grpc.web.MethodType.UNARY,
  proto.pbs.Major,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Major} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Major,
 *   !proto.pbs.Empty>}
 */
const methodInfo_MajorService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Major} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Major} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.MajorServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.MajorService/Delete',
      request,
      metadata || {},
      methodDescriptor_MajorService_Delete,
      callback);
};


/**
 * @param {!proto.pbs.Major} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.MajorServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.MajorService/Delete',
      request,
      metadata || {},
      methodDescriptor_MajorService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Major,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_MajorService_Edit = new grpc.web.MethodDescriptor(
  '/pbs.MajorService/Edit',
  grpc.web.MethodType.UNARY,
  proto.pbs.Major,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Major} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Major,
 *   !proto.pbs.Empty>}
 */
const methodInfo_MajorService_Edit = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Major} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Major} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.MajorServiceClient.prototype.edit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.MajorService/Edit',
      request,
      metadata || {},
      methodDescriptor_MajorService_Edit,
      callback);
};


/**
 * @param {!proto.pbs.Major} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.MajorServicePromiseClient.prototype.edit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.MajorService/Edit',
      request,
      metadata || {},
      methodDescriptor_MajorService_Edit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Major,
 *   !proto.pbs.Major>}
 */
const methodDescriptor_MajorService_View = new grpc.web.MethodDescriptor(
  '/pbs.MajorService/View',
  grpc.web.MethodType.UNARY,
  proto.pbs.Major,
  proto.pbs.Major,
  /**
   * @param {!proto.pbs.Major} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Major.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Major,
 *   !proto.pbs.Major>}
 */
const methodInfo_MajorService_View = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Major,
  /**
   * @param {!proto.pbs.Major} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Major.deserializeBinary
);


/**
 * @param {!proto.pbs.Major} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Major)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Major>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.MajorServiceClient.prototype.view =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.MajorService/View',
      request,
      metadata || {},
      methodDescriptor_MajorService_View,
      callback);
};


/**
 * @param {!proto.pbs.Major} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Major>}
 *     A native promise that resolves to the response
 */
proto.pbs.MajorServicePromiseClient.prototype.view =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.MajorService/View',
      request,
      metadata || {},
      methodDescriptor_MajorService_View);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.MajorSearchForm,
 *   !proto.pbs.Majors>}
 */
const methodDescriptor_MajorService_Search = new grpc.web.MethodDescriptor(
  '/pbs.MajorService/Search',
  grpc.web.MethodType.UNARY,
  proto.pbs.MajorSearchForm,
  proto.pbs.Majors,
  /**
   * @param {!proto.pbs.MajorSearchForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Majors.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.MajorSearchForm,
 *   !proto.pbs.Majors>}
 */
const methodInfo_MajorService_Search = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Majors,
  /**
   * @param {!proto.pbs.MajorSearchForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Majors.deserializeBinary
);


/**
 * @param {!proto.pbs.MajorSearchForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Majors)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Majors>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.MajorServiceClient.prototype.search =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.MajorService/Search',
      request,
      metadata || {},
      methodDescriptor_MajorService_Search,
      callback);
};


/**
 * @param {!proto.pbs.MajorSearchForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Majors>}
 *     A native promise that resolves to the response
 */
proto.pbs.MajorServicePromiseClient.prototype.search =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.MajorService/Search',
      request,
      metadata || {},
      methodDescriptor_MajorService_Search);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.MyMajorListReq,
 *   !proto.pbs.Majors>}
 */
const methodDescriptor_MajorService_MyMajorList = new grpc.web.MethodDescriptor(
  '/pbs.MajorService/MyMajorList',
  grpc.web.MethodType.UNARY,
  proto.pbs.MyMajorListReq,
  proto.pbs.Majors,
  /**
   * @param {!proto.pbs.MyMajorListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Majors.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.MyMajorListReq,
 *   !proto.pbs.Majors>}
 */
const methodInfo_MajorService_MyMajorList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Majors,
  /**
   * @param {!proto.pbs.MyMajorListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Majors.deserializeBinary
);


/**
 * @param {!proto.pbs.MyMajorListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Majors)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Majors>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.MajorServiceClient.prototype.myMajorList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.MajorService/MyMajorList',
      request,
      metadata || {},
      methodDescriptor_MajorService_MyMajorList,
      callback);
};


/**
 * @param {!proto.pbs.MyMajorListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Majors>}
 *     A native promise that resolves to the response
 */
proto.pbs.MajorServicePromiseClient.prototype.myMajorList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.MajorService/MyMajorList',
      request,
      metadata || {},
      methodDescriptor_MajorService_MyMajorList);
};


module.exports = proto.pbs;

