// source: place.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var major_pb = require('./major_pb.js');
goog.object.extend(proto, major_pb);
var user_pb = require('./user_pb.js');
goog.object.extend(proto, user_pb);
goog.exportSymbol('proto.pbs.ListPlaceReq', null, global);
goog.exportSymbol('proto.pbs.Place', null, global);
goog.exportSymbol('proto.pbs.PlaceSearchForm', null, global);
goog.exportSymbol('proto.pbs.Places', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ListPlaceReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ListPlaceReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ListPlaceReq.displayName = 'proto.pbs.ListPlaceReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Places = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Places.repeatedFields_, null);
};
goog.inherits(proto.pbs.Places, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Places.displayName = 'proto.pbs.Places';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.PlaceSearchForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.PlaceSearchForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.PlaceSearchForm.displayName = 'proto.pbs.PlaceSearchForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Place = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Place.repeatedFields_, null);
};
goog.inherits(proto.pbs.Place, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Place.displayName = 'proto.pbs.Place';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ListPlaceReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ListPlaceReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ListPlaceReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ListPlaceReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    schoolId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    majorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    keyword: jspb.Message.getFieldWithDefault(msg, 4, ""),
    page: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 6, 0),
    placeType: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ListPlaceReq}
 */
proto.pbs.ListPlaceReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ListPlaceReq;
  return proto.pbs.ListPlaceReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ListPlaceReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ListPlaceReq}
 */
proto.pbs.ListPlaceReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlaceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ListPlaceReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ListPlaceReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ListPlaceReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ListPlaceReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPlaceType();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string school_id = 1;
 * @return {string}
 */
proto.pbs.ListPlaceReq.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ListPlaceReq} returns this
 */
proto.pbs.ListPlaceReq.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string faculty_id = 2;
 * @return {string}
 */
proto.pbs.ListPlaceReq.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ListPlaceReq} returns this
 */
proto.pbs.ListPlaceReq.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string major_id = 3;
 * @return {string}
 */
proto.pbs.ListPlaceReq.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ListPlaceReq} returns this
 */
proto.pbs.ListPlaceReq.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string keyword = 4;
 * @return {string}
 */
proto.pbs.ListPlaceReq.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ListPlaceReq} returns this
 */
proto.pbs.ListPlaceReq.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 page = 5;
 * @return {number}
 */
proto.pbs.ListPlaceReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ListPlaceReq} returns this
 */
proto.pbs.ListPlaceReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 page_size = 6;
 * @return {number}
 */
proto.pbs.ListPlaceReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ListPlaceReq} returns this
 */
proto.pbs.ListPlaceReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 place_type = 7;
 * @return {number}
 */
proto.pbs.ListPlaceReq.prototype.getPlaceType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ListPlaceReq} returns this
 */
proto.pbs.ListPlaceReq.prototype.setPlaceType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Places.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Places.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Places.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Places} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Places.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pbs.Place.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Places}
 */
proto.pbs.Places.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Places;
  return proto.pbs.Places.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Places} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Places}
 */
proto.pbs.Places.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.Place;
      reader.readMessage(value,proto.pbs.Place.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPagesize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Places.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Places.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Places} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Places.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.Place.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated Place data = 1;
 * @return {!Array<!proto.pbs.Place>}
 */
proto.pbs.Places.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pbs.Place>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Place, 1));
};


/**
 * @param {!Array<!proto.pbs.Place>} value
 * @return {!proto.pbs.Places} returns this
*/
proto.pbs.Places.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Place=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Place}
 */
proto.pbs.Places.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Place, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Places} returns this
 */
proto.pbs.Places.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pbs.Places.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Places} returns this
 */
proto.pbs.Places.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pageSize = 3;
 * @return {number}
 */
proto.pbs.Places.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Places} returns this
 */
proto.pbs.Places.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.PlaceSearchForm.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.PlaceSearchForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.PlaceSearchForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlaceSearchForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagesize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.PlaceSearchForm}
 */
proto.pbs.PlaceSearchForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.PlaceSearchForm;
  return proto.pbs.PlaceSearchForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.PlaceSearchForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.PlaceSearchForm}
 */
proto.pbs.PlaceSearchForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPagesize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.PlaceSearchForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.PlaceSearchForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.PlaceSearchForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.PlaceSearchForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string keyword = 1;
 * @return {string}
 */
proto.pbs.PlaceSearchForm.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.PlaceSearchForm} returns this
 */
proto.pbs.PlaceSearchForm.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 pageSize = 2;
 * @return {number}
 */
proto.pbs.PlaceSearchForm.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlaceSearchForm} returns this
 */
proto.pbs.PlaceSearchForm.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 page = 3;
 * @return {number}
 */
proto.pbs.PlaceSearchForm.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.PlaceSearchForm} returns this
 */
proto.pbs.PlaceSearchForm.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Place.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Place.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Place.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Place} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Place.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    placename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    placeaddr: jspb.Message.getFieldWithDefault(msg, 3, ""),
    placeserver: jspb.Message.getFieldWithDefault(msg, 4, ""),
    placedb: jspb.Message.getFieldWithDefault(msg, 5, ""),
    placestatus: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    placecreator: (f = msg.getPlacecreator()) && user_pb.User.toObject(includeInstance, f),
    placemajorList: jspb.Message.toObjectList(msg.getPlacemajorList(),
    major_pb.Major.toObject, includeInstance),
    placedeleteat: jspb.Message.getFieldWithDefault(msg, 18, 0),
    placeupdateat: jspb.Message.getFieldWithDefault(msg, 9, 0),
    placecreateat: jspb.Message.getFieldWithDefault(msg, 10, 0),
    schoolId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    provinceCode: jspb.Message.getFieldWithDefault(msg, 13, ""),
    cityCode: jspb.Message.getFieldWithDefault(msg, 14, ""),
    distinctCode: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Place}
 */
proto.pbs.Place.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Place;
  return proto.pbs.Place.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Place} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Place}
 */
proto.pbs.Place.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceaddr(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceserver(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacedb(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlacestatus(value);
      break;
    case 7:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setPlacecreator(value);
      break;
    case 8:
      var value = new major_pb.Major;
      reader.readMessage(value,major_pb.Major.deserializeBinaryFromReader);
      msg.addPlacemajor(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlacedeleteat(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaceupdateat(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlacecreateat(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvinceCode(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityCode(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistinctCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Place.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Place.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Place} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Place.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlacename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlaceaddr();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlaceserver();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPlacedb();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlacestatus();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPlacecreator();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getPlacemajorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      major_pb.Major.serializeBinaryToWriter
    );
  }
  f = message.getPlacedeleteat();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getPlaceupdateat();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPlacecreateat();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getProvinceCode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCityCode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDistinctCode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string placeId = 1;
 * @return {string}
 */
proto.pbs.Place.prototype.getPlaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setPlaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string placeName = 2;
 * @return {string}
 */
proto.pbs.Place.prototype.getPlacename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setPlacename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string placeAddr = 3;
 * @return {string}
 */
proto.pbs.Place.prototype.getPlaceaddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setPlaceaddr = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string placeServer = 4;
 * @return {string}
 */
proto.pbs.Place.prototype.getPlaceserver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setPlaceserver = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string placeDB = 5;
 * @return {string}
 */
proto.pbs.Place.prototype.getPlacedb = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setPlacedb = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool placeStatus = 6;
 * @return {boolean}
 */
proto.pbs.Place.prototype.getPlacestatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setPlacestatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional User placeCreator = 7;
 * @return {?proto.pbs.User}
 */
proto.pbs.Place.prototype.getPlacecreator = function() {
  return /** @type{?proto.pbs.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 7));
};


/**
 * @param {?proto.pbs.User|undefined} value
 * @return {!proto.pbs.Place} returns this
*/
proto.pbs.Place.prototype.setPlacecreator = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.clearPlacecreator = function() {
  return this.setPlacecreator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Place.prototype.hasPlacecreator = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated Major placeMajor = 8;
 * @return {!Array<!proto.pbs.Major>}
 */
proto.pbs.Place.prototype.getPlacemajorList = function() {
  return /** @type{!Array<!proto.pbs.Major>} */ (
    jspb.Message.getRepeatedWrapperField(this, major_pb.Major, 8));
};


/**
 * @param {!Array<!proto.pbs.Major>} value
 * @return {!proto.pbs.Place} returns this
*/
proto.pbs.Place.prototype.setPlacemajorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.pbs.Major=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Major}
 */
proto.pbs.Place.prototype.addPlacemajor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.pbs.Major, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.clearPlacemajorList = function() {
  return this.setPlacemajorList([]);
};


/**
 * optional int64 placeDeleteAt = 18;
 * @return {number}
 */
proto.pbs.Place.prototype.getPlacedeleteat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setPlacedeleteat = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 placeUpdateAt = 9;
 * @return {number}
 */
proto.pbs.Place.prototype.getPlaceupdateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setPlaceupdateat = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 placeCreateAt = 10;
 * @return {number}
 */
proto.pbs.Place.prototype.getPlacecreateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setPlacecreateat = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string school_id = 11;
 * @return {string}
 */
proto.pbs.Place.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string faculty_id = 12;
 * @return {string}
 */
proto.pbs.Place.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string province_code = 13;
 * @return {string}
 */
proto.pbs.Place.prototype.getProvinceCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setProvinceCode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string city_code = 14;
 * @return {string}
 */
proto.pbs.Place.prototype.getCityCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setCityCode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string distinct_code = 15;
 * @return {string}
 */
proto.pbs.Place.prototype.getDistinctCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Place} returns this
 */
proto.pbs.Place.prototype.setDistinctCode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


goog.object.extend(exports, proto.pbs);
