import { Component, OnInit } from '@angular/core';
import { ExamsService } from 'src/app/services/exams.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Router } from '@angular/router';

@Component({
  selector: 'app-result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./result-list.component.styl']
})
export class ResultListComponent implements OnInit {


  public user:any;
  public dataSet = [
    {
      examanswersList: [],
      examcreateat: 0,
      examdeleteat: 0,
      examexaminee: {
        userid: "", 
        username: "", 
        userfname: "", 
        usergender: 1, 
        useridcard: ""
      },
      examid: "",
      examreviewat: 0,
      examscore: 0,
      examsn: "",
      examstatus: 1,
      examteacher: undefined,
      examupdateat: 0,
      examvenue: {
        venueid: "", 
        venuesn: "", 
        venuemajor: {
          majorid:"",
          majorname: ""
        }, 
        venuestart: 0, 
        venuemaxcount: 0,
		majorsList: [],
      }
    }
  ];

  public size = 'default';

  public selectedDateType:any = "0";
  public selectedIsPass: any = "0";
  public dateRange: Date[] = [];
  private startValue: Date | null = null;
  private endValue: Date | null = null;

  constructor(private examsService: ExamsService,
    private message: NzMessageService,
    private router: Router) { }

  onDateChange(date: Date[]) {
    if (date.length != 0) {
      date[0] = new Date(common.dateFtt("yyyy-MM-dd",date[0]) + " 00:00:00");
      date[1] = new Date(common.dateFtt("yyyy-MM-dd",date[1]) + " 23:59:59");
    }
    this.startValue = date[0];
    this.endValue = date[1];
  }

  onSearch(){
    let startDate = this.startValue.valueOf() / 1000;
    let endDate = this.endValue.valueOf() / 1000;
	// let start = parseInt(startDate.toString())
	// let end = parseInt(endDate.toString())
    this.examsService.searchResult(parseInt(startDate.toString()),parseInt(endDate.toString()),this.selectedDateType,this.selectedIsPass,
      (response) => {
        console.log(response);
		let searchData = {start: startDate, end: endDate, selectedType: this.selectedDateType}
		sessionStorage.setItem("exam-list-search-time", JSON.stringify(searchData))
        this.dataSet = response.dataList;
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      })
  }

  onView(examId:string){
    this.router.navigate(['/main'],{ queryParams: { type: "viewResult",examId: examId } });
  }

  onViewReport(examId:string, paperType: any) {
    this.router.navigate(['/report'],{ queryParams: { examId: examId, paperType: paperType } });
  }

  ngOnInit() {
	let startDate, endDate
	let searchDataStr = sessionStorage.getItem("exam-list-search-time")
	if (searchDataStr != null) {
		let searchData = JSON.parse(searchDataStr);
		startDate = searchData.start;
		endDate = searchData.end;
		this.selectedDateType = searchData.selectedType
		this.startValue = new Date(startDate * 1000)
		this.endValue = new Date(endDate * 1000)
	} else {
		let end = new Date(new Date().toLocaleDateString());
		this.startValue = new Date(new Date().toLocaleDateString());
		end.setMonth(this.startValue.getMonth() +1);
		this.endValue = new Date(common.dateFtt("yyyy-MM-dd",end) + " 23:59:59");//new Date(end);
		startDate = this.startValue.valueOf() / 1000;
		endDate = this.endValue.valueOf() / 1000;
	}
    this.dateRange = [this.startValue,this.endValue];
    this.user = this.examsService.getUser();
    this.examsService.searchResult(parseInt(startDate.toString()),parseInt(endDate.toString()),this.selectedDateType,this.selectedIsPass,
      (response) => {
        console.log(response);
        this.dataSet = response.dataList;
		this.dataSet.map((item)=>{
			if (item.examvenue.majorsList.length > 0) {
			  let majorsName = ''
			  for (let j=0;j<item.examvenue.majorsList.length;j++) {
				if (j == item.examvenue.majorsList.length - 1) {
				  majorsName += item.examvenue.majorsList[j].majorname
				} else {
				  majorsName += item.examvenue.majorsList[j].majorname + "、"
				}
			  }
			  item.examvenue.venuemajor.majorname = majorsName
			}
		})
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      });
  }
  
}
