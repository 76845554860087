import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatDialogModule, MatRadioModule, MatCheckboxModule } from '@angular/material';
import { NzIconModule, NZ_ICON_DEFAULT_TWOTONE_COLOR, NZ_ICONS } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CdkTableModule } from "@angular/cdk/table";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { ModalModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';

import { NgZorroAntdModule, NZ_I18N, zh_CN } from 'ng-zorro-antd';

import { NgxEchartsModule } from 'ngx-echarts';

import { HeaderComponent } from './components/header/header.component';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { LeftComponent } from './components/left/left.component';
import { RightComponent } from './components/right/right.component';
import { EventService } from './event.service';
import { CountdownComponent } from './components/countdown/countdown.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ReviewpasswordComponent } from './components/reviewpassword/reviewpassword.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { EntranceComponent } from './components/entrance/entrance.component';
import { ResultListComponent } from './components/result-list/result-list.component';
import { ListHeaderComponent } from './components/list-header/list-header.component';

/** 配置 angular i18n **/
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { NumberPipePipe } from './pipe/number-pipe.pipe';
import { RegistrationComponent } from './components/registration/registration.component';
import { Step1Component } from './components/registration/step1/step1.component';
import { Step2Component } from './components/registration/step2/step2.component';
import { Step3Component } from './components/registration/step3/step3.component';
import { Step4Component } from './components/registration/step4/step4.component';
import { PrintTemplateComponent } from './components/print-template/print-template.component';
import { PersonalsettingComponent } from './components/personalsetting/personalsetting.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { DegreePipePipe } from './pipe/degree-pipe.pipe';
import { ReportComponent } from './components/report/report.component';
import { ReportHeaderComponent } from './components/report-header/report-header.component';
import { TypeTransPipePipe } from './pipe/type-trans-pipe.pipe';
import { FooterComponent } from './components/class/footer/footer.component';
import { ListComponent } from './components/class/list/list.component';
import { DetailComponent } from './components/class/detail/detail.component';
import { TeacherMainComponent } from './components/class/teacher-main/teacher-main.component';
import { ClassHeaderComponent } from './components/class/class-header/class-header.component';
import { Detail2Component } from './components/class/detail2/detail2.component';
import { CourseDifficultyPipe } from './pipe/course-difficulty.pipe';
import { MyCollectComponent } from './components/class/my-collect/my-collect.component';
import { PracticeListComponent } from './components/practice-list/practice-list.component';
import { PracticeReportComponent } from './components/practice-report/practice-report.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzFormModule } from 'ng-zorro-antd/form';


const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

registerLocaleData(zh);
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LeftComponent,
    RightComponent,
    MainContainerComponent,
    CountdownComponent,
    ConfirmComponent,
    ReviewpasswordComponent,
    LoginComponent,
    MainComponent,
    EntranceComponent,
    ListHeaderComponent,
    ResultListComponent,
    NumberPipePipe,
    RegistrationComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    PrintTemplateComponent,
    PersonalsettingComponent,
    AboutusComponent,
    DegreePipePipe,
    ReportComponent,
    ReportHeaderComponent,
    TypeTransPipePipe,
    FooterComponent,
    ListComponent,
    DetailComponent,
    TeacherMainComponent,
    ClassHeaderComponent,
    Detail2Component,
    MyCollectComponent,
    CourseDifficultyPipe,
    PracticeListComponent,
    PracticeReportComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    CdkTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    AppRoutingModule,
    NgxEchartsModule,
    PdfViewerModule,
    NzCardModule,
    NzIconModule,
    NzTimelineModule,
    NzFormModule
    //QRCodeModule
  ],
  providers: [
    EventService,
    { provide: NZ_I18N, useValue: zh_CN },
    { provide: NZ_ICONS, useValue: icons }
  ],
  entryComponents: [ConfirmComponent, ReviewpasswordComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
