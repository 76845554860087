// source: venue.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var user_pb = require('./user_pb.js');
goog.object.extend(proto, user_pb);
var major_pb = require('./major_pb.js');
goog.object.extend(proto, major_pb);
var paper_pb = require('./paper_pb.js');
goog.object.extend(proto, paper_pb);
var batch_pb = require('./batch_pb.js');
goog.object.extend(proto, batch_pb);
var place_pb = require('./place_pb.js');
goog.object.extend(proto, place_pb);
goog.exportSymbol('proto.pbs.GetVenuesReq', null, global);
goog.exportSymbol('proto.pbs.Venue', null, global);
goog.exportSymbol('proto.pbs.VenueListReq', null, global);
goog.exportSymbol('proto.pbs.VenueReq', null, global);
goog.exportSymbol('proto.pbs.VenueSearchForm', null, global);
goog.exportSymbol('proto.pbs.Venues', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.GetVenuesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.GetVenuesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.GetVenuesReq.displayName = 'proto.pbs.GetVenuesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.VenueListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.VenueListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.VenueListReq.displayName = 'proto.pbs.VenueListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Venues = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Venues.repeatedFields_, null);
};
goog.inherits(proto.pbs.Venues, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Venues.displayName = 'proto.pbs.Venues';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.VenueSearchForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.VenueSearchForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.VenueSearchForm.displayName = 'proto.pbs.VenueSearchForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.VenueReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.VenueReq.repeatedFields_, null);
};
goog.inherits(proto.pbs.VenueReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.VenueReq.displayName = 'proto.pbs.VenueReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Venue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Venue.repeatedFields_, null);
};
goog.inherits(proto.pbs.Venue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Venue.displayName = 'proto.pbs.Venue';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.GetVenuesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.GetVenuesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.GetVenuesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GetVenuesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    schoolId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    majorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.GetVenuesReq}
 */
proto.pbs.GetVenuesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.GetVenuesReq;
  return proto.pbs.GetVenuesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.GetVenuesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.GetVenuesReq}
 */
proto.pbs.GetVenuesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.GetVenuesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.GetVenuesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.GetVenuesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GetVenuesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string school_id = 1;
 * @return {string}
 */
proto.pbs.GetVenuesReq.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GetVenuesReq} returns this
 */
proto.pbs.GetVenuesReq.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string faculty_id = 2;
 * @return {string}
 */
proto.pbs.GetVenuesReq.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GetVenuesReq} returns this
 */
proto.pbs.GetVenuesReq.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string major_id = 3;
 * @return {string}
 */
proto.pbs.GetVenuesReq.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GetVenuesReq} returns this
 */
proto.pbs.GetVenuesReq.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 page = 4;
 * @return {number}
 */
proto.pbs.GetVenuesReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GetVenuesReq} returns this
 */
proto.pbs.GetVenuesReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 page_size = 5;
 * @return {number}
 */
proto.pbs.GetVenuesReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GetVenuesReq} returns this
 */
proto.pbs.GetVenuesReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.VenueListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.VenueListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.VenueListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.VenueListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    majorId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    batchId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    placeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.VenueListReq}
 */
proto.pbs.VenueListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.VenueListReq;
  return proto.pbs.VenueListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.VenueListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.VenueListReq}
 */
proto.pbs.VenueListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.VenueListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.VenueListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.VenueListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.VenueListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBatchId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string major_id = 1;
 * @return {string}
 */
proto.pbs.VenueListReq.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.VenueListReq} returns this
 */
proto.pbs.VenueListReq.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string batch_id = 2;
 * @return {string}
 */
proto.pbs.VenueListReq.prototype.getBatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.VenueListReq} returns this
 */
proto.pbs.VenueListReq.prototype.setBatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string place_id = 3;
 * @return {string}
 */
proto.pbs.VenueListReq.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.VenueListReq} returns this
 */
proto.pbs.VenueListReq.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 page = 4;
 * @return {number}
 */
proto.pbs.VenueListReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.VenueListReq} returns this
 */
proto.pbs.VenueListReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 page_size = 5;
 * @return {number}
 */
proto.pbs.VenueListReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.VenueListReq} returns this
 */
proto.pbs.VenueListReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Venues.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Venues.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Venues.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Venues} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Venues.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pbs.Venue.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Venues}
 */
proto.pbs.Venues.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Venues;
  return proto.pbs.Venues.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Venues} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Venues}
 */
proto.pbs.Venues.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.Venue;
      reader.readMessage(value,proto.pbs.Venue.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPagesize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Venues.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Venues.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Venues} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Venues.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.Venue.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated Venue data = 1;
 * @return {!Array<!proto.pbs.Venue>}
 */
proto.pbs.Venues.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pbs.Venue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Venue, 1));
};


/**
 * @param {!Array<!proto.pbs.Venue>} value
 * @return {!proto.pbs.Venues} returns this
*/
proto.pbs.Venues.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Venue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Venue}
 */
proto.pbs.Venues.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Venue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Venues} returns this
 */
proto.pbs.Venues.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pbs.Venues.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Venues} returns this
 */
proto.pbs.Venues.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pageSize = 3;
 * @return {number}
 */
proto.pbs.Venues.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Venues} returns this
 */
proto.pbs.Venues.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.VenueSearchForm.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.VenueSearchForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.VenueSearchForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.VenueSearchForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    start: jspb.Message.getFieldWithDefault(msg, 3, 0),
    end: jspb.Message.getFieldWithDefault(msg, 4, 0),
    showexp: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    pagesize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    page: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.VenueSearchForm}
 */
proto.pbs.VenueSearchForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.VenueSearchForm;
  return proto.pbs.VenueSearchForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.VenueSearchForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.VenueSearchForm}
 */
proto.pbs.VenueSearchForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnd(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowexp(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPagesize(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.VenueSearchForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.VenueSearchForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.VenueSearchForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.VenueSearchForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getShowexp();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string keyword = 1;
 * @return {string}
 */
proto.pbs.VenueSearchForm.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.VenueSearchForm} returns this
 */
proto.pbs.VenueSearchForm.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 status = 2;
 * @return {number}
 */
proto.pbs.VenueSearchForm.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.VenueSearchForm} returns this
 */
proto.pbs.VenueSearchForm.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 start = 3;
 * @return {number}
 */
proto.pbs.VenueSearchForm.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.VenueSearchForm} returns this
 */
proto.pbs.VenueSearchForm.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 end = 4;
 * @return {number}
 */
proto.pbs.VenueSearchForm.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.VenueSearchForm} returns this
 */
proto.pbs.VenueSearchForm.prototype.setEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool showExp = 7;
 * @return {boolean}
 */
proto.pbs.VenueSearchForm.prototype.getShowexp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.VenueSearchForm} returns this
 */
proto.pbs.VenueSearchForm.prototype.setShowexp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int64 pageSize = 5;
 * @return {number}
 */
proto.pbs.VenueSearchForm.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.VenueSearchForm} returns this
 */
proto.pbs.VenueSearchForm.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 page = 6;
 * @return {number}
 */
proto.pbs.VenueSearchForm.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.VenueSearchForm} returns this
 */
proto.pbs.VenueSearchForm.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.VenueReq.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.VenueReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.VenueReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.VenueReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.VenueReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    paperId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    placeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    majorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    batchId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    schoolId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    majorIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    classIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    venueStartTime: jspb.Message.getFieldWithDefault(msg, 10, 0),
    venueMaxCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    venueApplyDue: jspb.Message.getFieldWithDefault(msg, 12, 0),
    venueId: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.VenueReq}
 */
proto.pbs.VenueReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.VenueReq;
  return proto.pbs.VenueReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.VenueReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.VenueReq}
 */
proto.pbs.VenueReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaperId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addMajorIds(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addClassIds(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVenueStartTime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVenueMaxCount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVenueApplyDue(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.VenueReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.VenueReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.VenueReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.VenueReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaperId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBatchId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMajorIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getClassIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getVenueStartTime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getVenueMaxCount();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getVenueApplyDue();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getVenueId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string paper_id = 1;
 * @return {string}
 */
proto.pbs.VenueReq.prototype.getPaperId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setPaperId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string place_id = 2;
 * @return {string}
 */
proto.pbs.VenueReq.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string major_id = 3;
 * @return {string}
 */
proto.pbs.VenueReq.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string batch_id = 4;
 * @return {string}
 */
proto.pbs.VenueReq.prototype.getBatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setBatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string school_id = 5;
 * @return {string}
 */
proto.pbs.VenueReq.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string faculty_id = 6;
 * @return {string}
 */
proto.pbs.VenueReq.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string major_ids = 7;
 * @return {!Array<string>}
 */
proto.pbs.VenueReq.prototype.getMajorIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setMajorIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.addMajorIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.clearMajorIdsList = function() {
  return this.setMajorIdsList([]);
};


/**
 * repeated string class_ids = 8;
 * @return {!Array<string>}
 */
proto.pbs.VenueReq.prototype.getClassIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setClassIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.addClassIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.clearClassIdsList = function() {
  return this.setClassIdsList([]);
};


/**
 * optional uint32 status = 9;
 * @return {number}
 */
proto.pbs.VenueReq.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 venue_start_time = 10;
 * @return {number}
 */
proto.pbs.VenueReq.prototype.getVenueStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setVenueStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 venue_max_count = 11;
 * @return {number}
 */
proto.pbs.VenueReq.prototype.getVenueMaxCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setVenueMaxCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 venue_apply_due = 12;
 * @return {number}
 */
proto.pbs.VenueReq.prototype.getVenueApplyDue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setVenueApplyDue = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string venue_id = 13;
 * @return {string}
 */
proto.pbs.VenueReq.prototype.getVenueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.VenueReq} returns this
 */
proto.pbs.VenueReq.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Venue.repeatedFields_ = [8,22,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Venue.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Venue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Venue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Venue.toObject = function(includeInstance, msg) {
  var f, obj = {
    venueid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    venuesn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    venuemajor: (f = msg.getVenuemajor()) && major_pb.Major.toObject(includeInstance, f),
    venuestart: jspb.Message.getFieldWithDefault(msg, 4, 0),
    venuemaxcount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    venuepaper: (f = msg.getVenuepaper()) && paper_pb.Paper.toObject(includeInstance, f),
    venueplace: (f = msg.getVenueplace()) && place_pb.Place.toObject(includeInstance, f),
    venuebatch: (f = msg.getVenuebatch()) && batch_pb.Batch.toObject(includeInstance, f),
    venuestatus: jspb.Message.getFieldWithDefault(msg, 21, 0),
    venuecreator: (f = msg.getVenuecreator()) && user_pb.User.toObject(includeInstance, f),
    venueapplydue: jspb.Message.getFieldWithDefault(msg, 11, 0),
    venueexamineeList: jspb.Message.toObjectList(msg.getVenueexamineeList(),
    user_pb.User.toObject, includeInstance),
    venueupdateat: jspb.Message.getFieldWithDefault(msg, 9, 0),
    venuecreateat: jspb.Message.getFieldWithDefault(msg, 10, 0),
    venuedeleteat: jspb.Message.getFieldWithDefault(msg, 18, 0),
    majorsList: jspb.Message.toObjectList(msg.getMajorsList(),
    major_pb.Major.toObject, includeInstance),
    facultyId: jspb.Message.getFieldWithDefault(msg, 23, ""),
    schoolId: jspb.Message.getFieldWithDefault(msg, 24, ""),
    classIdsList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Venue}
 */
proto.pbs.Venue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Venue;
  return proto.pbs.Venue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Venue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Venue}
 */
proto.pbs.Venue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVenueid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVenuesn(value);
      break;
    case 3:
      var value = new major_pb.Major;
      reader.readMessage(value,major_pb.Major.deserializeBinaryFromReader);
      msg.setVenuemajor(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVenuestart(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVenuemaxcount(value);
      break;
    case 5:
      var value = new paper_pb.Paper;
      reader.readMessage(value,paper_pb.Paper.deserializeBinaryFromReader);
      msg.setVenuepaper(value);
      break;
    case 20:
      var value = new place_pb.Place;
      reader.readMessage(value,place_pb.Place.deserializeBinaryFromReader);
      msg.setVenueplace(value);
      break;
    case 6:
      var value = new batch_pb.Batch;
      reader.readMessage(value,batch_pb.Batch.deserializeBinaryFromReader);
      msg.setVenuebatch(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVenuestatus(value);
      break;
    case 7:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setVenuecreator(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVenueapplydue(value);
      break;
    case 8:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.addVenueexaminee(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVenueupdateat(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVenuecreateat(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVenuedeleteat(value);
      break;
    case 22:
      var value = new major_pb.Major;
      reader.readMessage(value,major_pb.Major.deserializeBinaryFromReader);
      msg.addMajors(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.addClassIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Venue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Venue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Venue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Venue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVenueid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVenuesn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVenuemajor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      major_pb.Major.serializeBinaryToWriter
    );
  }
  f = message.getVenuestart();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getVenuemaxcount();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getVenuepaper();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      paper_pb.Paper.serializeBinaryToWriter
    );
  }
  f = message.getVenueplace();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      place_pb.Place.serializeBinaryToWriter
    );
  }
  f = message.getVenuebatch();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      batch_pb.Batch.serializeBinaryToWriter
    );
  }
  f = message.getVenuestatus();
  if (f !== 0) {
    writer.writeUint32(
      21,
      f
    );
  }
  f = message.getVenuecreator();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getVenueapplydue();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getVenueexamineeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getVenueupdateat();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getVenuecreateat();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getVenuedeleteat();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getMajorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      major_pb.Major.serializeBinaryToWriter
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getClassIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      25,
      f
    );
  }
};


/**
 * optional string venueId = 1;
 * @return {string}
 */
proto.pbs.Venue.prototype.getVenueid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.setVenueid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string venueSn = 2;
 * @return {string}
 */
proto.pbs.Venue.prototype.getVenuesn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.setVenuesn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Major venueMajor = 3;
 * @return {?proto.pbs.Major}
 */
proto.pbs.Venue.prototype.getVenuemajor = function() {
  return /** @type{?proto.pbs.Major} */ (
    jspb.Message.getWrapperField(this, major_pb.Major, 3));
};


/**
 * @param {?proto.pbs.Major|undefined} value
 * @return {!proto.pbs.Venue} returns this
*/
proto.pbs.Venue.prototype.setVenuemajor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.clearVenuemajor = function() {
  return this.setVenuemajor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Venue.prototype.hasVenuemajor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 venueStart = 4;
 * @return {number}
 */
proto.pbs.Venue.prototype.getVenuestart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.setVenuestart = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 venueMaxCount = 14;
 * @return {number}
 */
proto.pbs.Venue.prototype.getVenuemaxcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.setVenuemaxcount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional Paper venuePaper = 5;
 * @return {?proto.pbs.Paper}
 */
proto.pbs.Venue.prototype.getVenuepaper = function() {
  return /** @type{?proto.pbs.Paper} */ (
    jspb.Message.getWrapperField(this, paper_pb.Paper, 5));
};


/**
 * @param {?proto.pbs.Paper|undefined} value
 * @return {!proto.pbs.Venue} returns this
*/
proto.pbs.Venue.prototype.setVenuepaper = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.clearVenuepaper = function() {
  return this.setVenuepaper(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Venue.prototype.hasVenuepaper = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Place venuePlace = 20;
 * @return {?proto.pbs.Place}
 */
proto.pbs.Venue.prototype.getVenueplace = function() {
  return /** @type{?proto.pbs.Place} */ (
    jspb.Message.getWrapperField(this, place_pb.Place, 20));
};


/**
 * @param {?proto.pbs.Place|undefined} value
 * @return {!proto.pbs.Venue} returns this
*/
proto.pbs.Venue.prototype.setVenueplace = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.clearVenueplace = function() {
  return this.setVenueplace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Venue.prototype.hasVenueplace = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional Batch venueBatch = 6;
 * @return {?proto.pbs.Batch}
 */
proto.pbs.Venue.prototype.getVenuebatch = function() {
  return /** @type{?proto.pbs.Batch} */ (
    jspb.Message.getWrapperField(this, batch_pb.Batch, 6));
};


/**
 * @param {?proto.pbs.Batch|undefined} value
 * @return {!proto.pbs.Venue} returns this
*/
proto.pbs.Venue.prototype.setVenuebatch = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.clearVenuebatch = function() {
  return this.setVenuebatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Venue.prototype.hasVenuebatch = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 venueStatus = 21;
 * @return {number}
 */
proto.pbs.Venue.prototype.getVenuestatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.setVenuestatus = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional User venueCreator = 7;
 * @return {?proto.pbs.User}
 */
proto.pbs.Venue.prototype.getVenuecreator = function() {
  return /** @type{?proto.pbs.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 7));
};


/**
 * @param {?proto.pbs.User|undefined} value
 * @return {!proto.pbs.Venue} returns this
*/
proto.pbs.Venue.prototype.setVenuecreator = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.clearVenuecreator = function() {
  return this.setVenuecreator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Venue.prototype.hasVenuecreator = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 venueApplyDue = 11;
 * @return {number}
 */
proto.pbs.Venue.prototype.getVenueapplydue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.setVenueapplydue = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated User venueExaminee = 8;
 * @return {!Array<!proto.pbs.User>}
 */
proto.pbs.Venue.prototype.getVenueexamineeList = function() {
  return /** @type{!Array<!proto.pbs.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, user_pb.User, 8));
};


/**
 * @param {!Array<!proto.pbs.User>} value
 * @return {!proto.pbs.Venue} returns this
*/
proto.pbs.Venue.prototype.setVenueexamineeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.pbs.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.User}
 */
proto.pbs.Venue.prototype.addVenueexaminee = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.pbs.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.clearVenueexamineeList = function() {
  return this.setVenueexamineeList([]);
};


/**
 * optional int64 venueUpdateAt = 9;
 * @return {number}
 */
proto.pbs.Venue.prototype.getVenueupdateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.setVenueupdateat = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 venueCreateAt = 10;
 * @return {number}
 */
proto.pbs.Venue.prototype.getVenuecreateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.setVenuecreateat = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 venueDeleteAt = 18;
 * @return {number}
 */
proto.pbs.Venue.prototype.getVenuedeleteat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.setVenuedeleteat = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * repeated Major majors = 22;
 * @return {!Array<!proto.pbs.Major>}
 */
proto.pbs.Venue.prototype.getMajorsList = function() {
  return /** @type{!Array<!proto.pbs.Major>} */ (
    jspb.Message.getRepeatedWrapperField(this, major_pb.Major, 22));
};


/**
 * @param {!Array<!proto.pbs.Major>} value
 * @return {!proto.pbs.Venue} returns this
*/
proto.pbs.Venue.prototype.setMajorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.pbs.Major=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Major}
 */
proto.pbs.Venue.prototype.addMajors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.pbs.Major, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.clearMajorsList = function() {
  return this.setMajorsList([]);
};


/**
 * optional string faculty_id = 23;
 * @return {string}
 */
proto.pbs.Venue.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string school_id = 24;
 * @return {string}
 */
proto.pbs.Venue.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated string class_ids = 25;
 * @return {!Array<string>}
 */
proto.pbs.Venue.prototype.getClassIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.setClassIdsList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.addClassIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Venue} returns this
 */
proto.pbs.Venue.prototype.clearClassIdsList = function() {
  return this.setClassIdsList([]);
};


goog.object.extend(exports, proto.pbs);
