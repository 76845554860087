import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.styl']
})
export class MainContainerComponent implements OnInit {

  @Input() scoreAll;
  @Input() examId;
  constructor() { }

  ngOnInit() {
  }

}
