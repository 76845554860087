import { Injectable } from '@angular/core';
import { Service } from './service';
import { ClassServiceClient } from '../pbs/class_grpc_web_pb';
import { MyClassListReq,Classes } from '../pbs/class_pb';
import { Empty } from '../pbs/common_pb';
import { isNull } from 'util';

@Injectable({
  providedIn: 'root'
})
export class ClassService extends Service {

  classService: ClassServiceClient;

  constructor() { 
    super()
    this.classService = new ClassServiceClient(this.url, null, null);
  }

  /**
   * @description 获取班级列表
   * @author Megan
   * @date 2020-08-21
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof classService
   */
  getClassList(req,callback:Function=null,fail:Function=null) {
    this.initToken();
    let isOk = false;
    this.classService.myClassList(req,
      this.metadata, (err:Error, response: Classes) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }

}
