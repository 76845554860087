import { Injectable } from '@angular/core';
import { Service } from './service';
import { ExamServiceClient } from '../pbs/exams_grpc_web_pb';
import { Exams,Exam,ApplyForm,SearchExamForm,AnsForm,ExistExamResp } from '../pbs/exams_pb';
import { Empty } from '../pbs/common_pb';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExamsService extends Service {

  examsService:ExamServiceClient
  constructor() { 
    super()
    this.examsService = new ExamServiceClient(this.url,null,null);
  }

  
  /**
   * @description 获得成绩列表
   * @author Megan
   * @date 2019-09-15
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof ExamsService
   */
  getResultList(callback:Function=null,fail:Function=null) {
    this.initToken();
    let isOk = false;
    this.examsService.listResult(new Empty(),
      this.metadata, (err:Error, response: Exams) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response);
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }


  /**
   * @description 获取成绩详细
   * @author Megan
   * @date 2019-09-15
   * @param {String} examid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof ExamsService
   */
  getResult(examid:String,callback:Function,fail:Function=null) {
    this.initToken();
    let isOk = false;
    let exam = new Exam();
    exam.setExamid(examid);
    this.examsService.getResult(exam,
      this.metadata, (err: Error, response: Exam) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        } 
      }
    )
  }

  /**
   * @description 报名
   * @author Megan
   * @date 2019-09-21
   * @param {string} param
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof ExamsService
   */
  applayForm(param: string,callback:Function,fail:Function=null) {
    this.initToken();
    let isOk = false;
    let parm = JSON.parse(param);
    parm.venueExaminee.userbirthday = parseInt((new Date(parm.venueExaminee.userbirthday).valueOf() / 1000).toString());
    let applyForm = new ApplyForm();
    applyForm.setFname(parm.venueExaminee.userfname);
    applyForm.setGender(parm.venueExaminee.usergender);
    applyForm.setBirthday(parm.venueExaminee.userbirthday);
    applyForm.setIdcard(parm.venueExaminee.useridcard);
    applyForm.setNation(parm.venueExaminee.usernation);
    applyForm.setDegree(parm.venueExaminee.userdegree);
    applyForm.setAvatar(parm.venueExaminee.useravatar);
	applyForm.setAvatar(parm.venueExaminee.headimg);
    applyForm.setMobile(parm.venueExaminee.usermobile);
    applyForm.setVenueid(parm.venueId);
    this.examsService.apply(applyForm,
      this.metadata, (err: Error, response: Exam) => {
        if(!isOk) {
          if (!err && callback != null) {
            isOk = true;
            let exam = response.toObject();
            callback(exam);
            this.removeUser();
            exam.examexaminee.usertoken = this.metadata.token;
			if (exam.examexaminee.useravatar != "") {
				exam.examexaminee.useravatar = environment.domainName + exam.examexaminee.useravatar;
			}
            this.saveUser(exam.examexaminee);
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
        
      }
    )
  }


  /**
   * @description 开始考试
   * @author Megan
   * @date 2019-09-26
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof ExamsService
   */
  startExam(callback:Function=null,fail:Function=null) {
    this.initToken();
    let isOk = false;
    this.examsService.startExam(new Empty(),
      this.metadata, (err: Error, response: Exam) => {
        if(!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }

  /**
   * @description 获取考试成绩列表
   * @author Megan
   * @date 2019-09-26
   * @param {number} start
   * @param {number} end
   * @param {number} type
   * @param {number} pass
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof ExamsService
   */
  searchResult(start: number,end: number,type: number,pass: number,callback:Function=null,fail:Function=null) {
    this.initToken();
    let isOk = false;
    let searchExamForm = new SearchExamForm();
    searchExamForm.setStart(start);
    searchExamForm.setEnd(end);
    searchExamForm.setType(type);
    searchExamForm.setPass(pass);
    this.examsService.searchResult(searchExamForm,
      this.metadata, (err: Error, response: Exams) => {
        if(!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }

  /**
   * @description 提交考试试卷
   * @author Megan
   * @date 2019-09-26
   * @param {any} examanswers
   * @param {string} examid
   * @param {boolean} submit
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof ExamsService
   */
  commitAnswering(examanswers: any,examid: string,submit:boolean,callback:Function,fail:Function=null) {
    this.initToken();
    let isOk = false;
    let ansForm = new AnsForm();
    for (let i = 0; i < examanswers.length; i++) {
      ansForm.addExamanswers(examanswers[i]);
    }
    ansForm.setExamid(examid);
    ansForm.setSubmit(submit);
    this.examsService.answering(ansForm,
      this.metadata, (err: Error, response: Empty) => {
        if(!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback();
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }  
      }
    )
  }

  // /**
	//  * 保存用户当前考试试卷
	//  */
	// saveExam(exam:Exam) {
  //   localStorage.setItem('userExam', JSON.stringify(exam));
  // }

  // /**
	//  * 获取用户当前考试试卷
	//  */
  // getExam(): Exam{
  //   let examStr = localStorage.getItem('userExam');
	// 	return JSON.parse(examStr) as Exam;
  // }
	/**
	 * @description 开始考试
	 * @author Megan
	 * @date 2019-09-26
	 * @param {Function} [callback=null]
	 * @param {Function} [fail=null]
	 * @memberof ExamsService
	 */
	checkExistExam(callback:Function=null,fail:Function=null) {
		this.initToken();
		let isOk = false;
		
	 this.examsService.checkExistExam(new Empty(),
	   this.metadata, (err:Error, response: ExistExamResp) => {
	     if (!isOk) {
	       if (!err && callback != null) {
	         isOk = true;
	         callback(response);
	       }
	       
	       if (err && fail != null) {
	         isOk = true;
	         fail(err.message);
	       }
	     }
	   }
	 )
  }
  
}
