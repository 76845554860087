import { Component,OnInit } from '@angular/core';
import {UserService} from './services/user.service';
import { Router } from '@angular/router';
import { ExamsService } from './services/exams.service';
import { NzMessageService } from 'ng-zorro-antd';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit {
	
  title = 'cnc-web';
  private timer: any;
  private user: any;


  constructor(private service:UserService,private router: Router,private examsService: ExamsService,private message: NzMessageService) { }

  ngOnInit() {
    // this.router.navigateByUrl("/login");
    
    //定时任务
    this.timer = setInterval(() => {
      this.user = this.service.getUser();
      let exmaVenue = localStorage.getItem("examVenue");
      if (this.user && exmaVenue) {
        let exam = JSON.parse(exmaVenue);
        this.examsService.getResult(exam.examid,
          (response) => {
            if (response.examstatus < 3 || response.examstatus == 5) {
              if (this.examsService.isCommitExam()) {
                this.examsService.commitAnswering(exam.examanswersList,exam.examid,true,
                  (response) => {
                    localStorage.removeItem("examVenue");
                    localStorage.removeItem("questionData");
                  },
                  (fail) => {
                    this.message.create("error",decodeURI(fail));
                    if (decodeURI(fail) == "token无效") {
                      common.returnLogin(this.router, this.examsService, this.message);
                    }
                  }
                )
              } else {
                this.examsService.commitAnswering(exam.examanswersList,exam.examid,false,
                  (response) => {
                  },
                  (fail) => {
                    this.message.create("error",decodeURI(fail));
                    if (decodeURI(fail) == "token无效") {
                      common.returnLogin(this.router, this.examsService, this.message);
                    }
                  }
                )
              }
            }
          },
          (fail) => {
            this.message.create("error",decodeURI(fail));
          })
      }       
    },20000);
  }


  //测试GRPC调用
  getCode() {
   this.service.getVerifyCode((res)=>{
	   console.log(res)
   })
  }

}
