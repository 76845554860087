// source: exams.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var venue_pb = require('./venue_pb.js');
goog.object.extend(proto, venue_pb);
var user_pb = require('./user_pb.js');
goog.object.extend(proto, user_pb);
var cutter_pb = require('./cutter_pb.js');
goog.object.extend(proto, cutter_pb);
var blank_pb = require('./blank_pb.js');
goog.object.extend(proto, blank_pb);
var score_standard_pb = require('./score_standard_pb.js');
goog.object.extend(proto, score_standard_pb);
goog.exportSymbol('proto.pbs.AnsForm', null, global);
goog.exportSymbol('proto.pbs.ApplyForm', null, global);
goog.exportSymbol('proto.pbs.CutterAndBlankResp', null, global);
goog.exportSymbol('proto.pbs.Exam', null, global);
goog.exportSymbol('proto.pbs.ExamItemScore', null, global);
goog.exportSymbol('proto.pbs.Exams', null, global);
goog.exportSymbol('proto.pbs.ExistExamResp', null, global);
goog.exportSymbol('proto.pbs.GetExamListReq', null, global);
goog.exportSymbol('proto.pbs.GetExamsReq', null, global);
goog.exportSymbol('proto.pbs.RegisterAndApplyReq', null, global);
goog.exportSymbol('proto.pbs.SearchExamForm', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.RegisterAndApplyReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.RegisterAndApplyReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.RegisterAndApplyReq.displayName = 'proto.pbs.RegisterAndApplyReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.GetExamsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.GetExamsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.GetExamsReq.displayName = 'proto.pbs.GetExamsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.GetExamListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.GetExamListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.GetExamListReq.displayName = 'proto.pbs.GetExamListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.CutterAndBlankResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.CutterAndBlankResp.repeatedFields_, null);
};
goog.inherits(proto.pbs.CutterAndBlankResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.CutterAndBlankResp.displayName = 'proto.pbs.CutterAndBlankResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.AnsForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.AnsForm.repeatedFields_, null);
};
goog.inherits(proto.pbs.AnsForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.AnsForm.displayName = 'proto.pbs.AnsForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ExistExamResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ExistExamResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ExistExamResp.displayName = 'proto.pbs.ExistExamResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.SearchExamForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.SearchExamForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.SearchExamForm.displayName = 'proto.pbs.SearchExamForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ApplyForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ApplyForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ApplyForm.displayName = 'proto.pbs.ApplyForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Exams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Exams.repeatedFields_, null);
};
goog.inherits(proto.pbs.Exams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Exams.displayName = 'proto.pbs.Exams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.ExamItemScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pbs.ExamItemScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.ExamItemScore.displayName = 'proto.pbs.ExamItemScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pbs.Exam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pbs.Exam.repeatedFields_, null);
};
goog.inherits(proto.pbs.Exam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pbs.Exam.displayName = 'proto.pbs.Exam';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.RegisterAndApplyReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.RegisterAndApplyReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.RegisterAndApplyReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.RegisterAndApplyReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    schoolId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    studentId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 5, ""),
    remark: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.RegisterAndApplyReq}
 */
proto.pbs.RegisterAndApplyReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.RegisterAndApplyReq;
  return proto.pbs.RegisterAndApplyReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.RegisterAndApplyReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.RegisterAndApplyReq}
 */
proto.pbs.RegisterAndApplyReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStudentId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.RegisterAndApplyReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.RegisterAndApplyReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.RegisterAndApplyReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.RegisterAndApplyReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStudentId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.pbs.RegisterAndApplyReq.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterAndApplyReq} returns this
 */
proto.pbs.RegisterAndApplyReq.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string school_id = 2;
 * @return {string}
 */
proto.pbs.RegisterAndApplyReq.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterAndApplyReq} returns this
 */
proto.pbs.RegisterAndApplyReq.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string faculty_id = 3;
 * @return {string}
 */
proto.pbs.RegisterAndApplyReq.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterAndApplyReq} returns this
 */
proto.pbs.RegisterAndApplyReq.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string student_id = 4;
 * @return {string}
 */
proto.pbs.RegisterAndApplyReq.prototype.getStudentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterAndApplyReq} returns this
 */
proto.pbs.RegisterAndApplyReq.prototype.setStudentId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string mobile = 5;
 * @return {string}
 */
proto.pbs.RegisterAndApplyReq.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterAndApplyReq} returns this
 */
proto.pbs.RegisterAndApplyReq.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string remark = 6;
 * @return {string}
 */
proto.pbs.RegisterAndApplyReq.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.RegisterAndApplyReq} returns this
 */
proto.pbs.RegisterAndApplyReq.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.GetExamsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.GetExamsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.GetExamsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GetExamsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    schoolId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    facultyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    majorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    classId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    keyword: jspb.Message.getFieldWithDefault(msg, 5, ""),
    page: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.GetExamsReq}
 */
proto.pbs.GetExamsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.GetExamsReq;
  return proto.pbs.GetExamsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.GetExamsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.GetExamsReq}
 */
proto.pbs.GetExamsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchoolId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacultyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMajorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.GetExamsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.GetExamsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.GetExamsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GetExamsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchoolId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFacultyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMajorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClassId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string school_id = 1;
 * @return {string}
 */
proto.pbs.GetExamsReq.prototype.getSchoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GetExamsReq} returns this
 */
proto.pbs.GetExamsReq.prototype.setSchoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string faculty_id = 2;
 * @return {string}
 */
proto.pbs.GetExamsReq.prototype.getFacultyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GetExamsReq} returns this
 */
proto.pbs.GetExamsReq.prototype.setFacultyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string major_id = 3;
 * @return {string}
 */
proto.pbs.GetExamsReq.prototype.getMajorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GetExamsReq} returns this
 */
proto.pbs.GetExamsReq.prototype.setMajorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string class_id = 4;
 * @return {string}
 */
proto.pbs.GetExamsReq.prototype.getClassId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GetExamsReq} returns this
 */
proto.pbs.GetExamsReq.prototype.setClassId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string keyword = 5;
 * @return {string}
 */
proto.pbs.GetExamsReq.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GetExamsReq} returns this
 */
proto.pbs.GetExamsReq.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 page = 6;
 * @return {number}
 */
proto.pbs.GetExamsReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GetExamsReq} returns this
 */
proto.pbs.GetExamsReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 page_size = 7;
 * @return {number}
 */
proto.pbs.GetExamsReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GetExamsReq} returns this
 */
proto.pbs.GetExamsReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.GetExamListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.GetExamListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.GetExamListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GetExamListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    venueId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.GetExamListReq}
 */
proto.pbs.GetExamListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.GetExamListReq;
  return proto.pbs.GetExamListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.GetExamListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.GetExamListReq}
 */
proto.pbs.GetExamListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVenueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.GetExamListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.GetExamListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.GetExamListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.GetExamListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getVenueId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.pbs.GetExamListReq.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GetExamListReq} returns this
 */
proto.pbs.GetExamListReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 page = 2;
 * @return {number}
 */
proto.pbs.GetExamListReq.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GetExamListReq} returns this
 */
proto.pbs.GetExamListReq.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 page_size = 3;
 * @return {number}
 */
proto.pbs.GetExamListReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.GetExamListReq} returns this
 */
proto.pbs.GetExamListReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string venue_id = 4;
 * @return {string}
 */
proto.pbs.GetExamListReq.prototype.getVenueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.GetExamListReq} returns this
 */
proto.pbs.GetExamListReq.prototype.setVenueId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.CutterAndBlankResp.repeatedFields_ = [1,2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.CutterAndBlankResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.CutterAndBlankResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.CutterAndBlankResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CutterAndBlankResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    cutterList: jspb.Message.toObjectList(msg.getCutterList(),
    cutter_pb.Cutter.toObject, includeInstance),
    blankList: jspb.Message.toObjectList(msg.getBlankList(),
    blank_pb.Blank.toObject, includeInstance),
    result: (f = msg.getResult()) && common_pb.Result.toObject(includeInstance, f),
    workpieceList: jspb.Message.toObjectList(msg.getWorkpieceList(),
    score_standard_pb.WorkpieceParamMilling.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.CutterAndBlankResp}
 */
proto.pbs.CutterAndBlankResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.CutterAndBlankResp;
  return proto.pbs.CutterAndBlankResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.CutterAndBlankResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.CutterAndBlankResp}
 */
proto.pbs.CutterAndBlankResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cutter_pb.Cutter;
      reader.readMessage(value,cutter_pb.Cutter.deserializeBinaryFromReader);
      msg.addCutter(value);
      break;
    case 2:
      var value = new blank_pb.Blank;
      reader.readMessage(value,blank_pb.Blank.deserializeBinaryFromReader);
      msg.addBlank(value);
      break;
    case 3:
      var value = new common_pb.Result;
      reader.readMessage(value,common_pb.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 4:
      var value = new score_standard_pb.WorkpieceParamMilling;
      reader.readMessage(value,score_standard_pb.WorkpieceParamMilling.deserializeBinaryFromReader);
      msg.addWorkpiece(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.CutterAndBlankResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.CutterAndBlankResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.CutterAndBlankResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.CutterAndBlankResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCutterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      cutter_pb.Cutter.serializeBinaryToWriter
    );
  }
  f = message.getBlankList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      blank_pb.Blank.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.Result.serializeBinaryToWriter
    );
  }
  f = message.getWorkpieceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      score_standard_pb.WorkpieceParamMilling.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Cutter cutter = 1;
 * @return {!Array<!proto.pbs.Cutter>}
 */
proto.pbs.CutterAndBlankResp.prototype.getCutterList = function() {
  return /** @type{!Array<!proto.pbs.Cutter>} */ (
    jspb.Message.getRepeatedWrapperField(this, cutter_pb.Cutter, 1));
};


/**
 * @param {!Array<!proto.pbs.Cutter>} value
 * @return {!proto.pbs.CutterAndBlankResp} returns this
*/
proto.pbs.CutterAndBlankResp.prototype.setCutterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Cutter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Cutter}
 */
proto.pbs.CutterAndBlankResp.prototype.addCutter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Cutter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CutterAndBlankResp} returns this
 */
proto.pbs.CutterAndBlankResp.prototype.clearCutterList = function() {
  return this.setCutterList([]);
};


/**
 * repeated Blank blank = 2;
 * @return {!Array<!proto.pbs.Blank>}
 */
proto.pbs.CutterAndBlankResp.prototype.getBlankList = function() {
  return /** @type{!Array<!proto.pbs.Blank>} */ (
    jspb.Message.getRepeatedWrapperField(this, blank_pb.Blank, 2));
};


/**
 * @param {!Array<!proto.pbs.Blank>} value
 * @return {!proto.pbs.CutterAndBlankResp} returns this
*/
proto.pbs.CutterAndBlankResp.prototype.setBlankList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.pbs.Blank=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Blank}
 */
proto.pbs.CutterAndBlankResp.prototype.addBlank = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pbs.Blank, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CutterAndBlankResp} returns this
 */
proto.pbs.CutterAndBlankResp.prototype.clearBlankList = function() {
  return this.setBlankList([]);
};


/**
 * optional Result result = 3;
 * @return {?proto.pbs.Result}
 */
proto.pbs.CutterAndBlankResp.prototype.getResult = function() {
  return /** @type{?proto.pbs.Result} */ (
    jspb.Message.getWrapperField(this, common_pb.Result, 3));
};


/**
 * @param {?proto.pbs.Result|undefined} value
 * @return {!proto.pbs.CutterAndBlankResp} returns this
*/
proto.pbs.CutterAndBlankResp.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.CutterAndBlankResp} returns this
 */
proto.pbs.CutterAndBlankResp.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.CutterAndBlankResp.prototype.hasResult = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated WorkpieceParamMilling workpiece = 4;
 * @return {!Array<!proto.pbs.WorkpieceParamMilling>}
 */
proto.pbs.CutterAndBlankResp.prototype.getWorkpieceList = function() {
  return /** @type{!Array<!proto.pbs.WorkpieceParamMilling>} */ (
    jspb.Message.getRepeatedWrapperField(this, score_standard_pb.WorkpieceParamMilling, 4));
};


/**
 * @param {!Array<!proto.pbs.WorkpieceParamMilling>} value
 * @return {!proto.pbs.CutterAndBlankResp} returns this
*/
proto.pbs.CutterAndBlankResp.prototype.setWorkpieceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.pbs.WorkpieceParamMilling=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.WorkpieceParamMilling}
 */
proto.pbs.CutterAndBlankResp.prototype.addWorkpiece = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pbs.WorkpieceParamMilling, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.CutterAndBlankResp} returns this
 */
proto.pbs.CutterAndBlankResp.prototype.clearWorkpieceList = function() {
  return this.setWorkpieceList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.AnsForm.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.AnsForm.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.AnsForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.AnsForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.AnsForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    examanswersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    questionid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    examid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    submit: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.AnsForm}
 */
proto.pbs.AnsForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.AnsForm;
  return proto.pbs.AnsForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.AnsForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.AnsForm}
 */
proto.pbs.AnsForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addExamanswers(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubmit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.AnsForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.AnsForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.AnsForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.AnsForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamanswersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getQuestionid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExamid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubmit();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated string examAnswers = 1;
 * @return {!Array<string>}
 */
proto.pbs.AnsForm.prototype.getExamanswersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.AnsForm} returns this
 */
proto.pbs.AnsForm.prototype.setExamanswersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.AnsForm} returns this
 */
proto.pbs.AnsForm.prototype.addExamanswers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.AnsForm} returns this
 */
proto.pbs.AnsForm.prototype.clearExamanswersList = function() {
  return this.setExamanswersList([]);
};


/**
 * optional string questionId = 4;
 * @return {string}
 */
proto.pbs.AnsForm.prototype.getQuestionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.AnsForm} returns this
 */
proto.pbs.AnsForm.prototype.setQuestionid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string examId = 2;
 * @return {string}
 */
proto.pbs.AnsForm.prototype.getExamid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.AnsForm} returns this
 */
proto.pbs.AnsForm.prototype.setExamid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool submit = 3;
 * @return {boolean}
 */
proto.pbs.AnsForm.prototype.getSubmit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pbs.AnsForm} returns this
 */
proto.pbs.AnsForm.prototype.setSubmit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ExistExamResp.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ExistExamResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ExistExamResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExistExamResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    isExist: jspb.Message.getFieldWithDefault(msg, 1, 0),
    venue: (f = msg.getVenue()) && venue_pb.Venue.toObject(includeInstance, f),
    examId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ExistExamResp}
 */
proto.pbs.ExistExamResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ExistExamResp;
  return proto.pbs.ExistExamResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ExistExamResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ExistExamResp}
 */
proto.pbs.ExistExamResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIsExist(value);
      break;
    case 2:
      var value = new venue_pb.Venue;
      reader.readMessage(value,venue_pb.Venue.deserializeBinaryFromReader);
      msg.setVenue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ExistExamResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ExistExamResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ExistExamResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExistExamResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsExist();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getVenue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      venue_pb.Venue.serializeBinaryToWriter
    );
  }
  f = message.getExamId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 is_exist = 1;
 * @return {number}
 */
proto.pbs.ExistExamResp.prototype.getIsExist = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExistExamResp} returns this
 */
proto.pbs.ExistExamResp.prototype.setIsExist = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Venue venue = 2;
 * @return {?proto.pbs.Venue}
 */
proto.pbs.ExistExamResp.prototype.getVenue = function() {
  return /** @type{?proto.pbs.Venue} */ (
    jspb.Message.getWrapperField(this, venue_pb.Venue, 2));
};


/**
 * @param {?proto.pbs.Venue|undefined} value
 * @return {!proto.pbs.ExistExamResp} returns this
*/
proto.pbs.ExistExamResp.prototype.setVenue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.ExistExamResp} returns this
 */
proto.pbs.ExistExamResp.prototype.clearVenue = function() {
  return this.setVenue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.ExistExamResp.prototype.hasVenue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string exam_id = 3;
 * @return {string}
 */
proto.pbs.ExistExamResp.prototype.getExamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ExistExamResp} returns this
 */
proto.pbs.ExistExamResp.prototype.setExamId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.SearchExamForm.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.SearchExamForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.SearchExamForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SearchExamForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    end: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pass: jspb.Message.getFieldWithDefault(msg, 4, 0),
    page: jspb.Message.getFieldWithDefault(msg, 5, 0),
    keyword: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pagesize: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.SearchExamForm}
 */
proto.pbs.SearchExamForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.SearchExamForm;
  return proto.pbs.SearchExamForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.SearchExamForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.SearchExamForm}
 */
proto.pbs.SearchExamForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPass(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPagesize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.SearchExamForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.SearchExamForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.SearchExamForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.SearchExamForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPass();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 start = 1;
 * @return {number}
 */
proto.pbs.SearchExamForm.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SearchExamForm} returns this
 */
proto.pbs.SearchExamForm.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end = 2;
 * @return {number}
 */
proto.pbs.SearchExamForm.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SearchExamForm} returns this
 */
proto.pbs.SearchExamForm.prototype.setEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 type = 3;
 * @return {number}
 */
proto.pbs.SearchExamForm.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SearchExamForm} returns this
 */
proto.pbs.SearchExamForm.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 pass = 4;
 * @return {number}
 */
proto.pbs.SearchExamForm.prototype.getPass = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SearchExamForm} returns this
 */
proto.pbs.SearchExamForm.prototype.setPass = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 page = 5;
 * @return {number}
 */
proto.pbs.SearchExamForm.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SearchExamForm} returns this
 */
proto.pbs.SearchExamForm.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string keyword = 6;
 * @return {string}
 */
proto.pbs.SearchExamForm.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.SearchExamForm} returns this
 */
proto.pbs.SearchExamForm.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 pageSize = 7;
 * @return {number}
 */
proto.pbs.SearchExamForm.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.SearchExamForm} returns this
 */
proto.pbs.SearchExamForm.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ApplyForm.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ApplyForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ApplyForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ApplyForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    fname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 2, 0),
    birthday: jspb.Message.getFieldWithDefault(msg, 3, 0),
    idcard: jspb.Message.getFieldWithDefault(msg, 14, ""),
    nation: jspb.Message.getFieldWithDefault(msg, 24, ""),
    degree: jspb.Message.getFieldWithDefault(msg, 25, 0),
    avatar: jspb.Message.getFieldWithDefault(msg, 26, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 6, ""),
    venueid: jspb.Message.getFieldWithDefault(msg, 27, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ApplyForm}
 */
proto.pbs.ApplyForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ApplyForm;
  return proto.pbs.ApplyForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ApplyForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ApplyForm}
 */
proto.pbs.ApplyForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFname(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGender(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBirthday(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdcard(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setNation(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDegree(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setVenueid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ApplyForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ApplyForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ApplyForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ApplyForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGender();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBirthday();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIdcard();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getNation();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getDegree();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVenueid();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
};


/**
 * optional string fname = 1;
 * @return {string}
 */
proto.pbs.ApplyForm.prototype.getFname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ApplyForm} returns this
 */
proto.pbs.ApplyForm.prototype.setFname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 gender = 2;
 * @return {number}
 */
proto.pbs.ApplyForm.prototype.getGender = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ApplyForm} returns this
 */
proto.pbs.ApplyForm.prototype.setGender = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 birthday = 3;
 * @return {number}
 */
proto.pbs.ApplyForm.prototype.getBirthday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ApplyForm} returns this
 */
proto.pbs.ApplyForm.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string idCard = 14;
 * @return {string}
 */
proto.pbs.ApplyForm.prototype.getIdcard = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ApplyForm} returns this
 */
proto.pbs.ApplyForm.prototype.setIdcard = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string nation = 24;
 * @return {string}
 */
proto.pbs.ApplyForm.prototype.getNation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ApplyForm} returns this
 */
proto.pbs.ApplyForm.prototype.setNation = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional uint32 degree = 25;
 * @return {number}
 */
proto.pbs.ApplyForm.prototype.getDegree = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ApplyForm} returns this
 */
proto.pbs.ApplyForm.prototype.setDegree = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string avatar = 26;
 * @return {string}
 */
proto.pbs.ApplyForm.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ApplyForm} returns this
 */
proto.pbs.ApplyForm.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string mobile = 6;
 * @return {string}
 */
proto.pbs.ApplyForm.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ApplyForm} returns this
 */
proto.pbs.ApplyForm.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string venueId = 27;
 * @return {string}
 */
proto.pbs.ApplyForm.prototype.getVenueid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.ApplyForm} returns this
 */
proto.pbs.ApplyForm.prototype.setVenueid = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Exams.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Exams.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Exams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Exams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Exams.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.pbs.Exam.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagesize: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Exams}
 */
proto.pbs.Exams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Exams;
  return proto.pbs.Exams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Exams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Exams}
 */
proto.pbs.Exams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pbs.Exam;
      reader.readMessage(value,proto.pbs.Exam.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPagesize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Exams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Exams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Exams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Exams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.pbs.Exam.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPagesize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated Exam data = 1;
 * @return {!Array<!proto.pbs.Exam>}
 */
proto.pbs.Exams.prototype.getDataList = function() {
  return /** @type{!Array<!proto.pbs.Exam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pbs.Exam, 1));
};


/**
 * @param {!Array<!proto.pbs.Exam>} value
 * @return {!proto.pbs.Exams} returns this
*/
proto.pbs.Exams.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.pbs.Exam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pbs.Exam}
 */
proto.pbs.Exams.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pbs.Exam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Exams} returns this
 */
proto.pbs.Exams.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pbs.Exams.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exams} returns this
 */
proto.pbs.Exams.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pageSize = 3;
 * @return {number}
 */
proto.pbs.Exams.prototype.getPagesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exams} returns this
 */
proto.pbs.Exams.prototype.setPagesize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.ExamItemScore.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.ExamItemScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.ExamItemScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExamItemScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    blankScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    clampScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    cutterScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    machineScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    machineProcessScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    workpieceQualityScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    machineEffectiveScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    machineOperateScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.ExamItemScore}
 */
proto.pbs.ExamItemScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.ExamItemScore;
  return proto.pbs.ExamItemScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.ExamItemScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.ExamItemScore}
 */
proto.pbs.ExamItemScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBlankScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClampScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCutterScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMachineScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMachineProcessScore(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWorkpieceQualityScore(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMachineEffectiveScore(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMachineOperateScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.ExamItemScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.ExamItemScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.ExamItemScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.ExamItemScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlankScore();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getClampScore();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCutterScore();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMachineScore();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMachineProcessScore();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getWorkpieceQualityScore();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getMachineEffectiveScore();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getMachineOperateScore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional double blank_score = 1;
 * @return {number}
 */
proto.pbs.ExamItemScore.prototype.getBlankScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExamItemScore} returns this
 */
proto.pbs.ExamItemScore.prototype.setBlankScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double clamp_score = 2;
 * @return {number}
 */
proto.pbs.ExamItemScore.prototype.getClampScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExamItemScore} returns this
 */
proto.pbs.ExamItemScore.prototype.setClampScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double cutter_score = 3;
 * @return {number}
 */
proto.pbs.ExamItemScore.prototype.getCutterScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExamItemScore} returns this
 */
proto.pbs.ExamItemScore.prototype.setCutterScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double machine_score = 4;
 * @return {number}
 */
proto.pbs.ExamItemScore.prototype.getMachineScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExamItemScore} returns this
 */
proto.pbs.ExamItemScore.prototype.setMachineScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double machine_process_score = 5;
 * @return {number}
 */
proto.pbs.ExamItemScore.prototype.getMachineProcessScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExamItemScore} returns this
 */
proto.pbs.ExamItemScore.prototype.setMachineProcessScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double workpiece_quality_score = 6;
 * @return {number}
 */
proto.pbs.ExamItemScore.prototype.getWorkpieceQualityScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExamItemScore} returns this
 */
proto.pbs.ExamItemScore.prototype.setWorkpieceQualityScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double machine_effective_score = 7;
 * @return {number}
 */
proto.pbs.ExamItemScore.prototype.getMachineEffectiveScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExamItemScore} returns this
 */
proto.pbs.ExamItemScore.prototype.setMachineEffectiveScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double machine_operate_score = 8;
 * @return {number}
 */
proto.pbs.ExamItemScore.prototype.getMachineOperateScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.ExamItemScore} returns this
 */
proto.pbs.ExamItemScore.prototype.setMachineOperateScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pbs.Exam.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pbs.Exam.prototype.toObject = function(opt_includeInstance) {
  return proto.pbs.Exam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pbs.Exam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Exam.toObject = function(includeInstance, msg) {
  var f, obj = {
    examid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    examscore: jspb.Message.getFieldWithDefault(msg, 2, 0),
    examtotalscore: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    examvenue: (f = msg.getExamvenue()) && venue_pb.Venue.toObject(includeInstance, f),
    examexaminee: (f = msg.getExamexaminee()) && user_pb.User.toObject(includeInstance, f),
    examsn: jspb.Message.getFieldWithDefault(msg, 20, ""),
    examanswersList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    examstatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    examteacher: (f = msg.getExamteacher()) && user_pb.User.toObject(includeInstance, f),
    examdeleteat: jspb.Message.getFieldWithDefault(msg, 8, 0),
    examreviewat: jspb.Message.getFieldWithDefault(msg, 21, 0),
    examupdateat: jspb.Message.getFieldWithDefault(msg, 9, 0),
    examcreateat: jspb.Message.getFieldWithDefault(msg, 10, 0),
    examstarttime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    examlastupdatetime: jspb.Message.getFieldWithDefault(msg, 13, 0),
    examscorerefer: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    examItemScore: (f = msg.getExamItemScore()) && proto.pbs.ExamItemScore.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pbs.Exam}
 */
proto.pbs.Exam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pbs.Exam;
  return proto.pbs.Exam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pbs.Exam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pbs.Exam}
 */
proto.pbs.Exam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExamscore(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExamtotalscore(value);
      break;
    case 3:
      var value = new venue_pb.Venue;
      reader.readMessage(value,venue_pb.Venue.deserializeBinaryFromReader);
      msg.setExamvenue(value);
      break;
    case 4:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setExamexaminee(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setExamsn(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addExamanswers(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExamstatus(value);
      break;
    case 7:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.setExamteacher(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExamdeleteat(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExamreviewat(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExamupdateat(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExamcreateat(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExamstarttime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExamlastupdatetime(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExamscorerefer(value);
      break;
    case 15:
      var value = new proto.pbs.ExamItemScore;
      reader.readMessage(value,proto.pbs.ExamItemScore.deserializeBinaryFromReader);
      msg.setExamItemScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pbs.Exam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pbs.Exam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pbs.Exam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pbs.Exam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExamid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExamscore();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getExamtotalscore();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getExamvenue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      venue_pb.Venue.serializeBinaryToWriter
    );
  }
  f = message.getExamexaminee();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getExamsn();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getExamanswersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getExamstatus();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getExamteacher();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getExamdeleteat();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getExamreviewat();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getExamupdateat();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getExamcreateat();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getExamstarttime();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getExamlastupdatetime();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getExamscorerefer();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getExamItemScore();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.pbs.ExamItemScore.serializeBinaryToWriter
    );
  }
};


/**
 * optional string examId = 1;
 * @return {string}
 */
proto.pbs.Exam.prototype.getExamid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 examScore = 2;
 * @return {number}
 */
proto.pbs.Exam.prototype.getExamscore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamscore = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double examTotalScore = 11;
 * @return {number}
 */
proto.pbs.Exam.prototype.getExamtotalscore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamtotalscore = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional Venue examVenue = 3;
 * @return {?proto.pbs.Venue}
 */
proto.pbs.Exam.prototype.getExamvenue = function() {
  return /** @type{?proto.pbs.Venue} */ (
    jspb.Message.getWrapperField(this, venue_pb.Venue, 3));
};


/**
 * @param {?proto.pbs.Venue|undefined} value
 * @return {!proto.pbs.Exam} returns this
*/
proto.pbs.Exam.prototype.setExamvenue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.clearExamvenue = function() {
  return this.setExamvenue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Exam.prototype.hasExamvenue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional User examExaminee = 4;
 * @return {?proto.pbs.User}
 */
proto.pbs.Exam.prototype.getExamexaminee = function() {
  return /** @type{?proto.pbs.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 4));
};


/**
 * @param {?proto.pbs.User|undefined} value
 * @return {!proto.pbs.Exam} returns this
*/
proto.pbs.Exam.prototype.setExamexaminee = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.clearExamexaminee = function() {
  return this.setExamexaminee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Exam.prototype.hasExamexaminee = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string examSn = 20;
 * @return {string}
 */
proto.pbs.Exam.prototype.getExamsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamsn = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated string examAnswers = 5;
 * @return {!Array<string>}
 */
proto.pbs.Exam.prototype.getExamanswersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamanswersList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.addExamanswers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.clearExamanswersList = function() {
  return this.setExamanswersList([]);
};


/**
 * optional int64 examStatus = 6;
 * @return {number}
 */
proto.pbs.Exam.prototype.getExamstatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamstatus = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional User examTeacher = 7;
 * @return {?proto.pbs.User}
 */
proto.pbs.Exam.prototype.getExamteacher = function() {
  return /** @type{?proto.pbs.User} */ (
    jspb.Message.getWrapperField(this, user_pb.User, 7));
};


/**
 * @param {?proto.pbs.User|undefined} value
 * @return {!proto.pbs.Exam} returns this
*/
proto.pbs.Exam.prototype.setExamteacher = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.clearExamteacher = function() {
  return this.setExamteacher(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Exam.prototype.hasExamteacher = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 examDeleteAt = 8;
 * @return {number}
 */
proto.pbs.Exam.prototype.getExamdeleteat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamdeleteat = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 examReviewAt = 21;
 * @return {number}
 */
proto.pbs.Exam.prototype.getExamreviewat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamreviewat = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int64 examUpdateAt = 9;
 * @return {number}
 */
proto.pbs.Exam.prototype.getExamupdateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamupdateat = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 examCreateAt = 10;
 * @return {number}
 */
proto.pbs.Exam.prototype.getExamcreateat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamcreateat = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 examStartTime = 12;
 * @return {number}
 */
proto.pbs.Exam.prototype.getExamstarttime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamstarttime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 examLastUpdateTime = 13;
 * @return {number}
 */
proto.pbs.Exam.prototype.getExamlastupdatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamlastupdatetime = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional double examScoreRefer = 14;
 * @return {number}
 */
proto.pbs.Exam.prototype.getExamscorerefer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.setExamscorerefer = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional ExamItemScore exam_item_score = 15;
 * @return {?proto.pbs.ExamItemScore}
 */
proto.pbs.Exam.prototype.getExamItemScore = function() {
  return /** @type{?proto.pbs.ExamItemScore} */ (
    jspb.Message.getWrapperField(this, proto.pbs.ExamItemScore, 15));
};


/**
 * @param {?proto.pbs.ExamItemScore|undefined} value
 * @return {!proto.pbs.Exam} returns this
*/
proto.pbs.Exam.prototype.setExamItemScore = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pbs.Exam} returns this
 */
proto.pbs.Exam.prototype.clearExamItemScore = function() {
  return this.setExamItemScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pbs.Exam.prototype.hasExamItemScore = function() {
  return jspb.Message.getField(this, 15) != null;
};


goog.object.extend(exports, proto.pbs);
