/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var user_pb = require('./user_pb.js')

var major_pb = require('./major_pb.js')

var paper_pb = require('./paper_pb.js')

var batch_pb = require('./batch_pb.js')

var place_pb = require('./place_pb.js')
const proto = {};
proto.pbs = require('./venue_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.VenueServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.VenueServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.VenueListReq,
 *   !proto.pbs.Venues>}
 */
const methodDescriptor_VenueService_List = new grpc.web.MethodDescriptor(
  '/pbs.VenueService/List',
  grpc.web.MethodType.UNARY,
  proto.pbs.VenueListReq,
  proto.pbs.Venues,
  /**
   * @param {!proto.pbs.VenueListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Venues.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.VenueListReq,
 *   !proto.pbs.Venues>}
 */
const methodInfo_VenueService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Venues,
  /**
   * @param {!proto.pbs.VenueListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Venues.deserializeBinary
);


/**
 * @param {!proto.pbs.VenueListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Venues)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Venues>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.VenueServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.VenueService/List',
      request,
      metadata || {},
      methodDescriptor_VenueService_List,
      callback);
};


/**
 * @param {!proto.pbs.VenueListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Venues>}
 *     A native promise that resolves to the response
 */
proto.pbs.VenueServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.VenueService/List',
      request,
      metadata || {},
      methodDescriptor_VenueService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Venue,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_VenueService_Create = new grpc.web.MethodDescriptor(
  '/pbs.VenueService/Create',
  grpc.web.MethodType.UNARY,
  proto.pbs.Venue,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Venue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Venue,
 *   !proto.pbs.Empty>}
 */
const methodInfo_VenueService_Create = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Venue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Venue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.VenueServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.VenueService/Create',
      request,
      metadata || {},
      methodDescriptor_VenueService_Create,
      callback);
};


/**
 * @param {!proto.pbs.Venue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.VenueServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.VenueService/Create',
      request,
      metadata || {},
      methodDescriptor_VenueService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Venue,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_VenueService_Delete = new grpc.web.MethodDescriptor(
  '/pbs.VenueService/Delete',
  grpc.web.MethodType.UNARY,
  proto.pbs.Venue,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Venue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Venue,
 *   !proto.pbs.Empty>}
 */
const methodInfo_VenueService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Venue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Venue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.VenueServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.VenueService/Delete',
      request,
      metadata || {},
      methodDescriptor_VenueService_Delete,
      callback);
};


/**
 * @param {!proto.pbs.Venue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.VenueServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.VenueService/Delete',
      request,
      metadata || {},
      methodDescriptor_VenueService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Venue,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_VenueService_Edit = new grpc.web.MethodDescriptor(
  '/pbs.VenueService/Edit',
  grpc.web.MethodType.UNARY,
  proto.pbs.Venue,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Venue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Venue,
 *   !proto.pbs.Empty>}
 */
const methodInfo_VenueService_Edit = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Venue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Venue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.VenueServiceClient.prototype.edit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.VenueService/Edit',
      request,
      metadata || {},
      methodDescriptor_VenueService_Edit,
      callback);
};


/**
 * @param {!proto.pbs.Venue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.VenueServicePromiseClient.prototype.edit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.VenueService/Edit',
      request,
      metadata || {},
      methodDescriptor_VenueService_Edit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Venue,
 *   !proto.pbs.Venue>}
 */
const methodDescriptor_VenueService_View = new grpc.web.MethodDescriptor(
  '/pbs.VenueService/View',
  grpc.web.MethodType.UNARY,
  proto.pbs.Venue,
  proto.pbs.Venue,
  /**
   * @param {!proto.pbs.Venue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Venue.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Venue,
 *   !proto.pbs.Venue>}
 */
const methodInfo_VenueService_View = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Venue,
  /**
   * @param {!proto.pbs.Venue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Venue.deserializeBinary
);


/**
 * @param {!proto.pbs.Venue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Venue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Venue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.VenueServiceClient.prototype.view =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.VenueService/View',
      request,
      metadata || {},
      methodDescriptor_VenueService_View,
      callback);
};


/**
 * @param {!proto.pbs.Venue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Venue>}
 *     A native promise that resolves to the response
 */
proto.pbs.VenueServicePromiseClient.prototype.view =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.VenueService/View',
      request,
      metadata || {},
      methodDescriptor_VenueService_View);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.VenueSearchForm,
 *   !proto.pbs.Venues>}
 */
const methodDescriptor_VenueService_Search = new grpc.web.MethodDescriptor(
  '/pbs.VenueService/Search',
  grpc.web.MethodType.UNARY,
  proto.pbs.VenueSearchForm,
  proto.pbs.Venues,
  /**
   * @param {!proto.pbs.VenueSearchForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Venues.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.VenueSearchForm,
 *   !proto.pbs.Venues>}
 */
const methodInfo_VenueService_Search = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Venues,
  /**
   * @param {!proto.pbs.VenueSearchForm} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Venues.deserializeBinary
);


/**
 * @param {!proto.pbs.VenueSearchForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Venues)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Venues>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.VenueServiceClient.prototype.search =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.VenueService/Search',
      request,
      metadata || {},
      methodDescriptor_VenueService_Search,
      callback);
};


/**
 * @param {!proto.pbs.VenueSearchForm} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Venues>}
 *     A native promise that resolves to the response
 */
proto.pbs.VenueServicePromiseClient.prototype.search =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.VenueService/Search',
      request,
      metadata || {},
      methodDescriptor_VenueService_Search);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Venue,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_VenueService_CreateVenue = new grpc.web.MethodDescriptor(
  '/pbs.VenueService/CreateVenue',
  grpc.web.MethodType.UNARY,
  proto.pbs.Venue,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Venue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Venue,
 *   !proto.pbs.Empty>}
 */
const methodInfo_VenueService_CreateVenue = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Venue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Venue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.VenueServiceClient.prototype.createVenue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.VenueService/CreateVenue',
      request,
      metadata || {},
      methodDescriptor_VenueService_CreateVenue,
      callback);
};


/**
 * @param {!proto.pbs.Venue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.VenueServicePromiseClient.prototype.createVenue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.VenueService/CreateVenue',
      request,
      metadata || {},
      methodDescriptor_VenueService_CreateVenue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Venue,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_VenueService_EditVenue = new grpc.web.MethodDescriptor(
  '/pbs.VenueService/EditVenue',
  grpc.web.MethodType.UNARY,
  proto.pbs.Venue,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Venue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Venue,
 *   !proto.pbs.Empty>}
 */
const methodInfo_VenueService_EditVenue = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Venue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Venue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.VenueServiceClient.prototype.editVenue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.VenueService/EditVenue',
      request,
      metadata || {},
      methodDescriptor_VenueService_EditVenue,
      callback);
};


/**
 * @param {!proto.pbs.Venue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.VenueServicePromiseClient.prototype.editVenue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.VenueService/EditVenue',
      request,
      metadata || {},
      methodDescriptor_VenueService_EditVenue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.GetVenuesReq,
 *   !proto.pbs.Venues>}
 */
const methodDescriptor_VenueService_GetVenues = new grpc.web.MethodDescriptor(
  '/pbs.VenueService/GetVenues',
  grpc.web.MethodType.UNARY,
  proto.pbs.GetVenuesReq,
  proto.pbs.Venues,
  /**
   * @param {!proto.pbs.GetVenuesReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Venues.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.GetVenuesReq,
 *   !proto.pbs.Venues>}
 */
const methodInfo_VenueService_GetVenues = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Venues,
  /**
   * @param {!proto.pbs.GetVenuesReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Venues.deserializeBinary
);


/**
 * @param {!proto.pbs.GetVenuesReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Venues)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Venues>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.VenueServiceClient.prototype.getVenues =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.VenueService/GetVenues',
      request,
      metadata || {},
      methodDescriptor_VenueService_GetVenues,
      callback);
};


/**
 * @param {!proto.pbs.GetVenuesReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Venues>}
 *     A native promise that resolves to the response
 */
proto.pbs.VenueServicePromiseClient.prototype.getVenues =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.VenueService/GetVenues',
      request,
      metadata || {},
      methodDescriptor_VenueService_GetVenues);
};


module.exports = proto.pbs;

