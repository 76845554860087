import { Injectable } from '@angular/core';
import { Service } from './service';
import { ExamScoreServiceClient } from '../pbs/exam_score_grpc_web_pb';
import { ExamScore,ExamIdReq,ScoreSummaryResp,ProfessionalScoreResp,MachineOperationResp,MachineParamResp,OperateProcessResp } from '../pbs/exam_score_pb';
import { Empty } from '../pbs/common_pb';

@Injectable({
  providedIn: 'root'
})
export class ExamScoreService extends Service {

  examScoreService: ExamScoreServiceClient
  constructor() {
    super();
    this.examScoreService = new ExamScoreServiceClient(this.url,null,null);
  }

  /**
   * @description 获取成绩汇总
   * @author Megan
   * @date 2020-04-27
   * @param {String} examid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof ExamScoreService
   */
  getScoreSummary(examid:String,callback:Function,fail:Function=null) {
    //this.initToken();
    let isOk = false;
    let examIdReq = new ExamIdReq();
    examIdReq.setExamId(examid);
    this.examScoreService.getScoreSummary(examIdReq,
      this.metadata, (err: Error, response: ScoreSummaryResp) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        } 
      }
    )
  }

  /**
   * @description 获取职业素养成绩
   * @author Megan
   * @date 2020-04-27
   * @param {String} examid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof ExamScoreService
   */
  getProfessional(examid:String,callback:Function,fail:Function=null) {
    //this.initToken();
    let isOk = false;
    let examIdReq = new ExamIdReq();
    examIdReq.setExamId(examid);
    this.examScoreService.getProfessional(examIdReq,
      this.metadata, (err: Error, response: ProfessionalScoreResp) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        } 
      }
    )
  }

  /**
   * @description 机床操作成绩
   * @author Megan
   * @date 2020-04-27
   * @param {String} examid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof ExamScoreService
   */
  getMachineOperation(examid:String,callback:Function,fail:Function=null) {
    //this.initToken();
    let isOk = false;
    let examIdReq = new ExamIdReq();
    examIdReq.setExamId(examid);
    this.examScoreService.getMachineOperation(examIdReq,
      this.metadata, (err: Error, response: MachineOperationResp) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        } 
      }
    )
  }

  /**
   * @description 加工参数成绩
   * @author Megan
   * @date 2020-04-27
   * @param {String} examid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof ExamScoreService
   */
  getMachineParam(examid:String,callback:Function,fail:Function=null) {
    //this.initToken();
    let isOk = false;
    let examIdReq = new ExamIdReq();
    examIdReq.setExamId(examid);
    this.examScoreService.getMachineParam(examIdReq,
      this.metadata, (err: Error, response: MachineParamResp) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        } 
      }
    )
  }

  /**
   * @description 操作流程
   * @author Megan
   * @date 2020-04-27
   * @param {String} examid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof ExamScoreService
   */
  getOperateProcess(examid:String,callback:Function,fail:Function=null) {
    //this.initToken();
    let isOk = false;
    let examIdReq = new ExamIdReq();
    examIdReq.setExamId(examid);
    this.examScoreService.getOperateProcess(examIdReq,
      this.metadata, (err: Error, response: OperateProcessResp) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        } 
      }
    )
  }

  getAllTypeList(callback:Function=null,fail:Function=null) {
    //this.initToken();
    let isOk = false;
    this.examScoreService.getExamScoreAllTypeList(new Empty(),
      this.metadata, (err: Error, response: OperateProcessResp) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        } 
      }
    )
  }
}
