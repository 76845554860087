import {User} from '../pbs/user_pb';
import {Md5} from "ts-md5";

/**
 * @class Service
 * @desc RPC服务基类
 * @author Chen Meiujun
 * @date 2019-09-12
 */
export class Service {
	//protected url = 'http://10.0.0.55:8070';
	//protected url = 'http://58.246.129.34:8082'; //rpc服务端地址
	//protected url = 'http://localhost:8081'; //rpc服务端地址
	//protected url = 'http://47.103.15.129:81'
	// protected url = 'https://webrpc.vsteach.com'
	protected url = 'https://webrpc.vsteach.com'

	
	
	protected metadata = {'session':'','token':'', 'platform': ''} //metadata信息
	
	constructor() {
		this.initToken()
		this.metadata.session = this.session()
		this.metadata.platform = "web"
		this.isCommitExam();
	}
	
	session() {
		let sess = sessionStorage.getItem('session')
		if (!sess) {
			sess = String(Md5.hashStr(new Date().getTime().toLocaleString()));
			sessionStorage.setItem('session',sess)
		}
		return sess
	}
	
	
	/**
	 * 初始化token对象
	 */
	initToken(token:string="") {
		let localtoken = this.getToken();
		// if (localtoken != "") {
		// 	this.metadata.token = localtoken;
		// }
		// if (token != "" && this.metadata.token  == "") {
		// 	this.metadata.token  = token;
		// }
		if (localtoken != "") {
			this.metadata.token = localtoken;
		}
		if (token != "") {
			this.metadata.token  = token;
		}
	}
	
	
	
	/**
	 * 保存登录用户
	 */
	saveUser(user:User) {
		localStorage.setItem('userInfo', JSON.stringify(user));
	}
	
	/**
	 * 获取已登录用户
	 */
	getUser():User {
		let userStr = localStorage.getItem('userInfo');
		try {
			let user = JSON.parse(userStr);
			// if (user.useravatar != "") {
			// 	user.useravatar = environment.domainName + user.useravatar
			// }
			return user as User;
		}catch (e) {
			return null;
		}
		
	}
	
	/**
	 * 删除用户数据
	 */
	removeUser() {
		if (localStorage.hasOwnProperty("userInfo")) {
			localStorage.removeItem('userInfo');
		}
		sessionStorage.removeItem("exam-list-search-time") //删除成绩查询条件缓存
	}

	/**
	 * 获取已登录用户token
	 */
	getToken():string{
		let user = this.getUser();
		if (user) {
			return user.usertoken;
		}
		return ''
	}

	isCommitExam() {
		if (localStorage.hasOwnProperty("examVenue")) {
			let examVenue = JSON.parse(localStorage.getItem('examVenue'));
			let venueend = examVenue.examvenue.venuestart + examVenue.examvenue.venuepaper.paperdur;
			let currentDate = new Date().getTime() / 1000;
			if (parseInt(currentDate.toString()) < venueend) {
				return false;
			} else {
				return true;
			}
		}
	}

	/**
	 * 删除本地缓存的考试
	 */
	removeLocalExam() {
		if (localStorage.hasOwnProperty("examVenue")) {
				localStorage.removeItem('examVenue');
		}
		if (localStorage.hasOwnProperty("questionData")) {
			localStorage.removeItem('questionData');
		}
	}
}