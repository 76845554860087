import { Component, OnInit, AfterViewInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.styl']
})
export class CountdownComponent implements AfterViewInit, OnDestroy {

  constructor() { }

  // private startDate: Date = new Date();
  // 父组件传递截止日期
  @Input() startTime: string;
  @Input() endTime: string;
  // 父组件传递标题
  @Input() title: string;
  // 小时差
  private hour: number;
  // 分钟差
  private minute: number;
  // 秒数差
  private second: number;
  // 时间差
  private _diff: number = 0;

  public dif: string;

  public status: string;

  private get diff() {
      return this._diff;
  }
  private set diff(val) {
      this._diff = Math.floor(val / 1000);
      this.hour = Math.floor(this._diff / 3600);
      this.minute = Math.floor((this._diff % 3600) / 60);
      this.second = (this._diff % 3600) % 60;

      if (this._diff > 0){
        this.status = '考试中';
        if (this.hour != 0 ){ 
          this.dif = this.displayDiff(this.hour)+":"+this.displayDiff(this.minute)+":"+this.displayDiff(this.second);     
        } else if (this.minute != 0 ){
          this.dif = this.displayDiff(this.minute)+":"+this.displayDiff(this.second);
        } else if (this.second > 0 ) {
          this.dif = this.displayDiff(this.second);
        }
      } else {
        this.status = '考试结束';
        this.dif = '00:00:00';
      }
  }

  public displayDiff(time:number): any{
    let timeDisplay: any = '';
    if (time < 10) {
      timeDisplay = '0' + time.toString();
    } else {
      timeDisplay = time.toString();
    }
    return timeDisplay;
  }

  // 定时器
  private timer;

  // 每一秒更新时间差
  ngAfterViewInit() {
    this.timer = setInterval(() => {
        this.diff = new Date(this.endTime).valueOf() - Date.now();//Date.now();
    }, 1000);
  }

  // 销毁组件时清除定时器
  ngOnDestroy() {
      if (this.timer) {
          clearInterval(this.timer);
      }
  }

}
