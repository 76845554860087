import { Injectable } from '@angular/core';
import {Service} from './service';
import {UserServiceClient} from '../pbs/user_grpc_web_pb';
import { LoginUser,User,ChangePwdForm,UserForm, SmsCodeParam, ExamId } from '../pbs/user_pb';
import {Empty} from '../pbs/common_pb';
import { Error, Status } from 'grpc-web';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

/**
 * @class UserService
 * @desc 用户RPC服务类 调取远程服务器方法
 * @author Chen Meiujun
 * @date 2019-09-12
 */
export class UserService extends Service {

  userService:UserServiceClient //用户rpc客户端

  constructor() {
	  super()
	  this.userService = new UserServiceClient(this.url,null,null);
  }


  /**
   * @name getVerifyCode
   * @desc 获取登录验证码
   * @param callback 成功回调函数
   * @param fail 失败回调函数
   * @author Chen Meijun
   * @date 2019-09-12
   * @return LoginUser
   */
  getVerifyCode(callback:Function=null,fail:Function=null) {
	let isOk = false;
    this.userService.getCode(new Empty(),
		this.metadata, (err: Error, response:LoginUser)=> {
			if (!isOk) {
				if (!err && callback != null) {
					isOk = true;
					callback(response.getImgcode());
				}
				
				if (err && fail != null) {
					isOk = true;
					fail(err.message);
				}
			}
		});
				
	}

	getPhoneVerifyCode(phone:string, callback:Function=null,fail:Function=null) {
		let isOk = false;
		let codeParm = new SmsCodeParam()
		codeParm.setMobile(phone)
		codeParm.setSmsType(1)

    	this.userService.getCodeSms(codeParm,
			this.metadata, (err: Error, response:Empty)=> {
			if (!isOk) {
				if (!err && callback != null) {
					callback() //成功回调 不返回结果
					isOk = true;
				}
				
				if (err && fail != null) {
					fail(err.message) //失败回调
					isOk = true;
				}
			}
		});
	}
	
	/**
	 * @name login
	 * @desc 用户登录
	 * @param username 用户名
	 * @param passwd 密码
	 * @param code 验证码
	 * @param callback 成功回调函数
     * @param fail 失败回调函数
	 * @author Chen Meijun
	 * @date 2019-09-12
	 * @return {User}
	 */
	login(username:string,passwd:string,code:string,logintype:number,callback:Function,fail:Function=null) {
		let isOk = false;
		let user = new LoginUser()
		user.setName(username)
		user.setPwd(passwd)
		user.setCode(code)
		user.setLoginType(logintype)
		//   console.log('开始')
		this.userService.login(user,
			this.metadata, (err: Error, response:User)=> {
				if(!isOk) {
					if (!err && callback != null) {
						isOk = true;
						let user = this.getUser();
						if (user && user.userid != response.toObject().userid) {
							this.removeLocalExam();
						}
						let userInfo = response.toObject();
						if (userInfo.useravatar != "") {
							userInfo.useravatar = environment.domainName + userInfo.useravatar;
						}
						this.saveUser(userInfo) //本地保存用户
						this.initToken()//初始化token验证对象
						callback(response) //成功回调 返回用户对象
					}
					
					if (err && fail != null) {
						isOk = true;
						fail(err.message) //失败回调 直接传输错误原因string类型接收即可
					}
				}
				//   console.log('中')
					
			});
		// console.log('结束')	
		
					
		}


	/**
	 * @description 修改密码
	 * @author Megan
	 * @date 2019-09-15
	 * @param {string} pwd
	 * @param {string} oldPwd
	 * @param {Function} callback
	 * @param {Function} [fail=null]
	 * @memberof UserService
	 */
	changePwd(pwd:string,oldPwd:string,callback:Function,fail:Function=null) {
		this.initToken();
		let isOk = false;
		let form = new ChangePwdForm();
		form.setPwd(pwd);
		form.setOld(oldPwd);
		this.userService.changePwd(
				form, //修改密码表单参数
				this.metadata,//修改表单必须验证数据
				(err: Error, res:Empty)=> { //不是所有响应结果都是User 结果看proto来
					if(!isOk) {
						if (!err && callback != null) {
							callback() //成功回调 不返回结果
							isOk = true;
						}
						
						if (err && fail != null) {
							fail(err.message) //失败回调
							isOk = true;
						}
					}
					
				});
					  
	}

	/**
	 * @description 验证旧密码
	 * @author Megan
	 * @date 2019-09-24
	 * @param {string} oldPwd
	 * @param {Function} callback
	 * @param {Function} [fail=null]
	 * @memberof UserService
	 */
	checkOldPwd(oldPwd:string,callback:Function,fail:Function=null) {
		this.initToken();
		let isOk = false;
		let form = new ChangePwdForm();
		form.setOld(oldPwd);
		this.userService.checkOldPwd(
				form, //修改密码表单参数
				this.metadata,//修改表单必须验证数据
				(err: Error, res:Empty)=> { //不是所有响应结果都是User 结果看proto来
					if(!isOk) {
						if (!err && callback != null) {
							callback() //成功回调 不返回结果
							isOk = true;
						}
						
						if (err && fail != null) {
							fail(err.message) //失败回调
							isOk = true;
						}
					}
				});
	}

	/**
	 * @description 修改用户信息
	 * @author Megan
	 * @date 2019-09-24
	 * @param {string} param
	 * @param {Function} callback
	 * @param {Function} [fail=null]
	 * @memberof UserService
	 */
	edit(param: string,callback:Function,fail:Function=null) {
		this.initToken();
		let isOk = false;
		let parm = JSON.parse(param);
		parm.userbirthday = parseInt((new Date(parm.userbirthday).valueOf() / 1000).toString());
		let userFrom = new UserForm();
		userFrom.setFname(parm.userfname);
		userFrom.setGender(parm.usergender);
		userFrom.setIdcard(parm.useridcard);
		userFrom.setNation(parm.usernation);
		userFrom.setDegree(parm.userdegree);
		userFrom.setAvatar(parm.useravatar);
		if (parm.headimg) {
			userFrom.setAvatar(parm.headimg);
		}
		userFrom.setBirthday(parm.userbirthday);
		userFrom.setMobile(parm.usermobile);
		userFrom.setFacultyId(parm.facultyId);
		userFrom.setMajorId(parm.majorId);
		userFrom.setClassId(parm.classId);
		this.userService.edit(
			userFrom,
			this.metadata,
			(err: Error, res:User)=> { 
				if(!isOk) {
					if (!err && callback != null) {
						isOk = true;
						let user = res.toObject();
						// let user = this.getUser();
						// user.userfname = parm.userfname;
						// user.usergender = parm.usergender;
						// user.useridcard = parm.useridcard;
						// user.usernation = parm.usernation;
						// user.userdegree = parm.userdegree;
						// user.useravatar = parm.useravatar;
						// user.userbirthday = parm.userbirthday;
						// user.usermobile = parm.usermobile;
						this.removeUser();
						if (user.useravatar != "") {
							user.useravatar = environment.domainName + user.useravatar;
						}
						this.saveUser(user);
						callback() //成功回调 不返回结果
					}
					
					if (err && fail != null) {
						isOk = true;
						fail(err.message) //失败回调
					}
				}
				
			});
	}
	
	//获取用户信息
	getUserInfo(callback:Function,fail:Function=null) {
		this.initToken();
		let isOk = false;
		this.userService.view(
			new User(),
			this.metadata,
			(err: Error, res:User)=> { 
				if(!isOk) {
					if (!err && callback != null) {
						isOk = true;
						let user = res.toObject();
						callback(user) //成功回调 不返回结果
					}
					if (err && fail != null) {
						isOk = true;
						fail(err.message) //失败回调
					}
				}
				
			});
	}
	
	//根据examId获取用户信息
	getUserInfoByExamId(examId: string,callback:Function,fail:Function=null) {
		this.initToken();
		//this.metadata.token = token;
		let isOk = false;
		let examIdReq = new ExamId();
		examIdReq.setExamId(examId)
		this.userService.getUserByExamId(
			examIdReq,
			this.metadata,
			(err: Error, res:User)=> { 
				if(!isOk) {
					if (!err && callback != null) {
						isOk = true;
						let user = res.toObject();
						callback(user) //成功回调 不返回结果
					}
					if (err && fail != null) {
						isOk = true;
						fail(err.message) //失败回调
					}
				}
				
			});
	}
  
}
