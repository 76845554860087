import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input } from '@angular/core';
import { EventService } from 'src/app/event.service';
import { NzModalRef, NzModalService, NzMessageService } from 'ng-zorro-antd';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamsService } from 'src/app/services/exams.service';

@Component({
  selector: 'app-left',
  templateUrl: './left.component.html',
  styleUrls: ['./left.component.styl']
})
export class LeftComponent implements OnInit {

  constructor(private event: EventService,
    private el: ElementRef,
    private activatedRoute: ActivatedRoute,
    private examsService: ExamsService,
    private router: Router,
    private message: NzMessageService,
    private modalService: NzModalService) {
      activatedRoute.queryParams.subscribe(queryParams => {
        this.type = queryParams.type;
      })
    }

  public type:string; //页面类型viewResult试卷查看
  private len: any; //每一类题目数量
  private totalNum: any; //所以题目总数

  public num:number = 0; //未答题数量
  public actives:number = 0; //已答题数量
  public flags:number = 0; //标记题数量

  public menu:any; //所有题型并且包含题目

  private examanswersList:any = []; //考生答案数组
  private nzContentText: string = "您确定要提交吗";
  
  private examVenueStr: any; 
  private examVenue:any; 
  public questions:any = [];  //所有的题目数组
  public err: any;

  private examstatus:any;

  @Input() scoreAll;
  @Input() examId;
  
  //题型选择
  onSelect(item: any,et: any,i: any): void{
    this.actives = 0;
    this.flags = 0;
    for (let item of this.menu) {
      item.isCurrent = false;
    }
    console.log("selectItem",item);
    for (let qt of this.questions) {
      if (qt.isActive){
        this.actives = this.actives + 1;
      }
      if (qt.isFlag){
        this.flags = this.flags + 1;
      }
      this.len = qt.totalNum;
    }
    item.isCurrent = true;
    this.num = this.len - this.actives;
    this.event.publish(item.questions,this.err);
  }

  //题号选择
  onChoose(item: any,et: any,i: any): void{
    for (let index = 0; index < this.menu.length; index++) {
      this.menu[index].isCurrent = false;
      if (item.questiontype == this.menu[index].typeId) {
        this.menu[index].isCurrent = true;
      }
    }
    
    this.event.publish(item,this.err);
  }
  //交卷
  onCommit(): void{
    this.nzContentText = "您确定要提交吗";
    let exmaVenue = localStorage.getItem("examVenue");
    if (exmaVenue) {
      let exam = JSON.parse(exmaVenue);
	  console.log("exam", exam)
      this.examanswersList = exam.examanswersList;
      this.examanswersList.forEach(element => {
        let itm = JSON.parse(element);
		if (itm == null) {
			this.nzContentText = "您还有未完成的试题，确定提交吗？";
			return false;
		}
        if (itm.length == 0) {
          this.nzContentText = "您还有未完成的试题，确定提交吗？";
          return false;
        }
      });
    } else {
      this.nzContentText = "您还有未完成的试题，确定提交吗？";
    }
    const modal: NzModalRef = this.modalService.create({
      nzTitle: '完成提交',
      nzMaskClosable: false,
      nzContent: '<div class="modal-container">\
                    <div class="icon"><img src="/assets/img/tip.png" alt=""></div>\
                    <p>'+this.nzContentText+'</p>\
                  </div>',
      nzFooter: [
        {
          label: '确定',
          NzButtonShape: 'default',
          onClick: () => {
            this.examsService.commitAnswering(this.examanswersList,this.examId,true,
              (response) => {
                this.message.create("success","提交成功");
                modal.destroy();
                localStorage.removeItem("examVenue");
                localStorage.removeItem("questionData");
                this.router.navigate(['/entrance']);
              },
              (fail) => {
                this.message.create("error",decodeURI(fail));
                if (decodeURI(fail) == "token无效") {
                  common.returnLogin(this.router, this.examsService, this.message);
                }
                modal.destroy();
              })
            }
        },
        {
          label: '取消',
          type: 'primary',
          onClick: () =>  modal.destroy()
        }
      ]
    });
  }

  ngOnInit() {
    let exmaVenue = localStorage.getItem("examVenue");

    this.event.getExamStatusData().subscribe(data => {
      this.examstatus = data;
    });
    
    this.event.getMenuData().subscribe(data => {
      this.menu = data;
      data.forEach(el => {
        el.questions.forEach(item => {
          this.questions.push(item);
        })
      });
      if (this.menu) {
        // console.log("question",qtData);
        this.event.publish(this.questions,this.err);
      }
    });

      this.event.subscribe().subscribe(value => {
        // console.log("flag",value);
        if (this.menu) {
          this.actives = 0;
          this.flags = 0;
          for (let qt of this.questions) {
            if (qt.isActive){
              this.actives = this.actives + 1;
            }
            if (qt.isFlag){
              this.flags = this.flags + 1;
            }
            this.num = qt.totalNum - this.actives;
          }
        }
      });
  }
}