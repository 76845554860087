import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppComponent} from './app.component'
import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { EntranceComponent } from './components/entrance/entrance.component';
import { ResultListComponent } from './components/result-list/result-list.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { Step1Component } from './components/registration/step1/step1.component';
import { Step2Component } from './components/registration/step2/step2.component';
import { Step3Component } from './components/registration/step3/step3.component';
import { Step4Component } from './components/registration/step4/step4.component';
import { PrintTemplateComponent } from './components/print-template/print-template.component';
import { PersonalsettingComponent } from './components/personalsetting/personalsetting.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { ReportComponent } from './components/report/report.component';
import { ListComponent } from './components/class/list/list.component';
import { DetailComponent } from './components/class/detail/detail.component';
import { TeacherMainComponent } from './components/class/teacher-main/teacher-main.component';
import { Detail2Component } from './components/class/detail2/detail2.component';
import { MyCollectComponent } from './components/class/my-collect/my-collect.component';
import { PracticeListComponent } from './components/practice-list/practice-list.component';
import { PracticeReportComponent } from './components/practice-report/practice-report.component';

const routes: Routes = [
  {path:'',redirectTo: '/login',pathMatch: 'full'},
  {path:'login',component:LoginComponent},
  {path:'main',component:MainComponent},
  {path:'entrance',component:EntranceComponent},
  {path:'resultlist',component:ResultListComponent},
  {path:'registration/step1',component:Step1Component},
  {path:'registration/step2',component:Step2Component},
  {path:'registration/step3',component:Step3Component},
  {path:'registration/step4',component:Step4Component},
  {path:'print-template',component:PrintTemplateComponent},
  {path:'personalsetting',component:PersonalsettingComponent},
  {path:'about',component:AboutusComponent},
  {path:'report',component:ReportComponent},
  {path:'class/list',component:ListComponent},
  {path:'class/detail',component:DetailComponent},
  {path:'class/detail2',component:Detail2Component},
  {path:'class/teacher-main',component:TeacherMainComponent},
  {path:'class/myCollect',component:MyCollectComponent},
  {path:'practicelist',component:PracticeListComponent},
  {path:'practicereport',component:PracticeReportComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
