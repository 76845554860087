import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { NzMessageService } from 'ng-zorro-antd';
import { ExamsService } from 'src/app/services/exams.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.styl']
})
export class LoginComponent implements OnInit {

  public userAccount: any;
  public userPassword: any;
  public authCode: string;
  public authCodePath: string;
  public userPhone: any;
  public verifyCode: string;
  public verifyMessage: string = "获取验证码";
  public code: boolean = false;
  public isSend: boolean = false;
  private timer:any;

  constructor(private router: Router,
    private userService:UserService,
    private examsService:ExamsService,
    private message: NzMessageService ) { }

  public get verifyMsg() {
      return this.verifyMessage;
  }
  public set verifyMsg(msg) {
      this.verifyMessage = msg;
  }
  
  login(et:any, lgoinType:number) {
    if (lgoinType == 1) {
      this.userAccount = this.userPhone;
      this.authCode = this.verifyCode;
    }
    this.userService.login(this.userAccount,this.userPassword,this.authCode,lgoinType,
      (response) => {
        this.message.create("success", "登录成功");
        this.router.navigate(['/entrance']);
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
		    this.changeVerify();
      }
    );
  }

  regist() {
    window.open("https://www.vsteach.com/register");
  }

  onClick() {
    this.userAccount = "";
    this.userPassword ="";
    this.authCode = "";
    this.userPhone = "";
    this.verifyCode = "";
  }

  changeVerify() {
    this.userService.getVerifyCode((response) => {
      this.code = true;
      this.authCodePath = response;
    },
    (fail) => {
      this.message.create("error", decodeURI(fail));
    })
  }

  getVerify() {
    if (!this.isSend) {
      this.isSend = true;
      this.userService.getPhoneVerifyCode(this.userPhone,(response) => {
        this.message.create("success", "已发送");
        let num = 60; //60s
        var timer = setInterval(() => {
          num--;
          this.verifyMsg = num + "s";
          if (num == 0) {
              clearInterval(timer);
              this.verifyMsg = "获取验证码";
              this.isSend = false;
          }
        }, 1000);
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        this.isSend = false;
      })
    }
  }
  
  ngOnInit() {
    let user = this.userService.getUser();
    if (user) {
      this.router.navigate(['/entrance']);
    } else {
      this.changeVerify();
    }
  }
}
