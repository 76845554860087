/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var school_pb = require('./school_pb.js')
const proto = {};
proto.pbs = require('./faculty_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.FacultyServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.FacultyServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Faculty,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_FacultyService_Create = new grpc.web.MethodDescriptor(
  '/pbs.FacultyService/Create',
  grpc.web.MethodType.UNARY,
  proto.pbs.Faculty,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Faculty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Faculty,
 *   !proto.pbs.Empty>}
 */
const methodInfo_FacultyService_Create = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Faculty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Faculty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.FacultyServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.FacultyService/Create',
      request,
      metadata || {},
      methodDescriptor_FacultyService_Create,
      callback);
};


/**
 * @param {!proto.pbs.Faculty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.FacultyServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.FacultyService/Create',
      request,
      metadata || {},
      methodDescriptor_FacultyService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Faculty,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_FacultyService_Update = new grpc.web.MethodDescriptor(
  '/pbs.FacultyService/Update',
  grpc.web.MethodType.UNARY,
  proto.pbs.Faculty,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Faculty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Faculty,
 *   !proto.pbs.Empty>}
 */
const methodInfo_FacultyService_Update = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.Faculty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.Faculty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.FacultyServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.FacultyService/Update',
      request,
      metadata || {},
      methodDescriptor_FacultyService_Update,
      callback);
};


/**
 * @param {!proto.pbs.Faculty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.FacultyServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.FacultyService/Update',
      request,
      metadata || {},
      methodDescriptor_FacultyService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.FacultyId,
 *   !proto.pbs.Empty>}
 */
const methodDescriptor_FacultyService_Delete = new grpc.web.MethodDescriptor(
  '/pbs.FacultyService/Delete',
  grpc.web.MethodType.UNARY,
  proto.pbs.FacultyId,
  common_pb.Empty,
  /**
   * @param {!proto.pbs.FacultyId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.FacultyId,
 *   !proto.pbs.Empty>}
 */
const methodInfo_FacultyService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Empty,
  /**
   * @param {!proto.pbs.FacultyId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.pbs.FacultyId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.FacultyServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.FacultyService/Delete',
      request,
      metadata || {},
      methodDescriptor_FacultyService_Delete,
      callback);
};


/**
 * @param {!proto.pbs.FacultyId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Empty>}
 *     A native promise that resolves to the response
 */
proto.pbs.FacultyServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.FacultyService/Delete',
      request,
      metadata || {},
      methodDescriptor_FacultyService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.FacultyListReq,
 *   !proto.pbs.Faculties>}
 */
const methodDescriptor_FacultyService_List = new grpc.web.MethodDescriptor(
  '/pbs.FacultyService/List',
  grpc.web.MethodType.UNARY,
  proto.pbs.FacultyListReq,
  proto.pbs.Faculties,
  /**
   * @param {!proto.pbs.FacultyListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Faculties.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.FacultyListReq,
 *   !proto.pbs.Faculties>}
 */
const methodInfo_FacultyService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Faculties,
  /**
   * @param {!proto.pbs.FacultyListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Faculties.deserializeBinary
);


/**
 * @param {!proto.pbs.FacultyListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Faculties)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Faculties>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.FacultyServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.FacultyService/List',
      request,
      metadata || {},
      methodDescriptor_FacultyService_List,
      callback);
};


/**
 * @param {!proto.pbs.FacultyListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Faculties>}
 *     A native promise that resolves to the response
 */
proto.pbs.FacultyServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.FacultyService/List',
      request,
      metadata || {},
      methodDescriptor_FacultyService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Faculties>}
 */
const methodDescriptor_FacultyService_MyFacultyList = new grpc.web.MethodDescriptor(
  '/pbs.FacultyService/MyFacultyList',
  grpc.web.MethodType.UNARY,
  common_pb.Empty,
  proto.pbs.Faculties,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Faculties.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.Empty,
 *   !proto.pbs.Faculties>}
 */
const methodInfo_FacultyService_MyFacultyList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.Faculties,
  /**
   * @param {!proto.pbs.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.Faculties.deserializeBinary
);


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Faculties)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Faculties>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.FacultyServiceClient.prototype.myFacultyList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.FacultyService/MyFacultyList',
      request,
      metadata || {},
      methodDescriptor_FacultyService_MyFacultyList,
      callback);
};


/**
 * @param {!proto.pbs.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Faculties>}
 *     A native promise that resolves to the response
 */
proto.pbs.FacultyServicePromiseClient.prototype.myFacultyList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.FacultyService/MyFacultyList',
      request,
      metadata || {},
      methodDescriptor_FacultyService_MyFacultyList);
};


module.exports = proto.pbs;

