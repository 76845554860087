import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import { NzMessageService } from 'ng-zorro-antd';
import { environment } from 'src/environments/environment';
import { NzTabPosition } from 'ng-zorro-antd/tabs';
import { NzCardModule } from 'ng-zorro-antd/card';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'app-my-collect',
  templateUrl: './my-collect.component.html',
  styleUrls: ['./my-collect.component.styl'],
})
export class MyCollectComponent implements OnInit {
  validateForm: FormGroup;

  isVertical = false;

  public listData: any = [];
  public commentListData: any = [];
  public historyListData: any = [];
  public historyListData2: any = [];
  public friendListData: any = [];
  public pageSize: number = 12;
  public page: number = 1;
  public totalSize: number = 0;
  public user: any = {
    useravatar: "",
    userfname: ""
  };

  public host: string = environment.domainName;
  private courseDifficulty: any = -1;
  public difficultyData = [
    {
      value: 0,
      name: "入门",
      active: false
    },
    {
      value: 1,
      name: "初级",
      active: false
    },
    {
      value: 2,
      name: "中级",
      active: false
    },
    {
      value: 3,
      name: "高级",
      active: false
    }
  ];
  private categoryId: any = null;
  public keyword: string = null;

  tabs: Array<{ tid: number; content: string; }> = [];
  nzTabPosition: NzTabPosition = 'left';
  titleTab: string = '我的课程';
  sexData: Array<{ id: number, label: string; value: string }> = [];


  constructor(private router: Router,
    private userService: UserService,
    private courseService: CourseService,
    private message: NzMessageService,
    private fb: FormBuilder) {
    console.log(this.user, "user");
    this.validateForm = this.fb.group({
      userfname: ['', [Validators.required], [this.userNameAsyncValidator]],
      comment: ['', [Validators.required]],
      usergender: ['', [Validators.required]],
      userbirthday: ['', [Validators.required]],
      usermobile: ['', [Validators.required]],
      olduserpasswd: ['', [Validators.required]],
      userpasswd: ['', [Validators.required]],
      confirm: ['', [this.confirmValidator]],
    });

  }


  log(args: any[]): void {
    if (args.indexOf('click') == 0) {
      const tabId = args[1].tid
      this.titleTab = args[1].content;
      this.page = 1;
      switch (tabId) {
        case 0:
          this.getCourseList();
          break;
        case 1:
          this.getMyCollectCourse();
          break;
        case 2:
          this.getMyComment();
          break;
        case 3:
          this.getMyHistory();
          break;
        case 4:
          this.getMyFriend();
          break;
        case 5:
          this.getMyPersonal();
          break;
        default:
        // 如果没有匹配的 case，执行此代码块
      }
    }

  }

  onFav(item: any) {
    let type = 1; //0取消收藏 1收藏
    if (item.hasCollect) {
      type = 0
    } else {
      type = 1;
    }
    event.stopPropagation();
    this.courseService.setCollect(item.id, type,
      (response) => {
        this.listData.forEach(itm => {
          if (itm.id == item.id) {
            if (type == 0) {
              itm.hasCollect = false;
            } else {
              itm.hasCollect = true;
            }
            itm.collectNums = response.collectNum
          }
        });
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.courseService, this.message);
        }
      }
    );
  }

  onClick(item: any) {
    if (item.courseType == 0) {
      this.router.navigate(['/class/detail'], { queryParams: { courseId: item.id } });
    } else {
      this.router.navigate(['/class/detail2'], { queryParams: { courseId: item.id } });
    }
  }

  // 课程列表
  getCourseList() {
    let param = {
      categoryId: this.categoryId,
      courseDifficulty: this.courseDifficulty,
      creatorId: null,
      keyword: this.keyword,
      page: this.page,
      pageSize: this.pageSize
    }
    this.courseService.getList(JSON.stringify(param),
      (response) => {
        this.listData = response.dataList;
        this.totalSize = response.count;
        this.listData.forEach(itemA => {
          const matchedItem = this.difficultyData.find(itemB => itemB.value == itemA.courseDifficulty);
          if (matchedItem) {
            itemA.courseDifficulty = matchedItem.name;
          }
        });
        console.log(param);
        console.log(this.listData, "xiaolu------listdataa");
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.courseService, this.message);
        }
      }
    );
  }
  // 课程列表收藏
  getMyCollectCourse() {
    let param = {
      categoryId: this.categoryId,
      courseDifficulty: this.courseDifficulty,
      creatorId: null,
      keyword: this.keyword,
      page: this.page,
      pageSize: this.pageSize
    }
    this.courseService.getMyCollect(JSON.stringify(param),
      (response) => {
        this.listData = response.dataList;
        //this.user = response.dataList[0].courseCreator;
        this.totalSize = response.count;
        this.listData.forEach(itemA => {
          const matchedItem = this.difficultyData.find(itemB => itemB.value == itemA.courseDifficulty);
          if (matchedItem) {
            itemA.courseDifficulty = matchedItem.name;
          }
        });
        console.log(this.listData, "xiaolu------listdata收藏");
        console.log(response, "xiaolu------response");
        console.log(param, "xiaolu------param");
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.courseService, this.message);
        }
      }
    );
  }

  // 我的评论
  getMyComment() {
    this.commentListData = [
      {
        href: 'http://ant.design',
        title: 'ant design part1',
        avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        description: 'Ant Design, a design language for background applications, is refined by Ant UED Team1.',
      },
      {
        href: 'http://ant.design',
        title: 'ant design part2',
        avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        description: 'Ant Design, a design language for background applications, is refined by Ant UED Team2.',
      },
      {
        href: 'http://ant.design',
        title: 'ant design part3',
        avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        description: 'Ant Design, a design language for background applications, is refined by Ant UED Team3.',
      }
    ];
  }

  // 我的历史记录
  getMyHistory() {
    this.historyListData = [
      {
        DateDay: '今天',
        dataContent: [
          {
            time: '16:09',
            href: 'http://ant.design',
            title: 'CNC加工中心操机全套学习教程 学加工中心，UG编程，CNC操作机床',
            description: '内容简介：《CNC加工中心》操机全套学习教程 学加工中心，UG编程，CNC操机，机加工，UG,UG数控编程，CNC加工中心，UG建模，模具设计，数控编程，多轴编程等。',
            status: "0",
            schedule: 30,
          },
          {
            time: '11:09',
            href: 'http://ant.design',
            title: 'CNC加工中心操机全套学习教程 学加工中心，UG编程，CNC操作机床',
            description: '内容简介：《CNC加工中心》操机全套学习教程 学加工中心，UG编程，CNC操机，机加工，UG,UG数控编程，CNC加工中心，UG建模，模具设计，数控编程，多轴编程等。',
            status: "0",
            schedule: 22,
          },
          {
            time: '10:09',
            href: 'http://ant.design',
            title: 'CNC加工中心操机全套学习教程 学加工中心，UG编程，CNC操作机床',
            description: '内容简介：《CNC加工中心》操机全套学习教程 学加工中心，UG编程，CNC操机，机加工，UG,UG数控编程，CNC加工中心，UG建模，模具设计，数控编程，多轴编程等。',
            status: "0",
            schedule: 76,
          }
        ]
      },
      {
        DateDay: '今天1',
        dataContent: [
          {
            time: '16:09',
            href: 'http://ant.design',
            title: 'ant design part1',
            description: 'Ant Design, a design language for background applications, is refined by Ant UED Team1.',
            status: "0",
            schedule: 77,
          }
        ]
      },
      {
        DateDay: '今天2',
        dataContent: [
          {
            time: '16:09',
            href: 'http://ant.design',
            title: 'ant design part1',
            description: 'Ant Design, a design language for background applications, is refined by Ant UED Team1.',
            status: "2",
            schedule: 44,
          }
        ]
      },
      {
        DateDay: '今天3',
        dataContent: [
          {
            time: '16:09',
            href: 'http://ant.design',
            title: 'ant design part1',
            description: 'Ant Design, a design language for background applications, is refined by Ant UED Team1.',
            status: "0",
            schedule: 52,
          }
        ]
      },
      {
        DateDay: '今天4',
        dataContent: [
          {
            time: '16:09',
            href: 'http://ant.design',
            title: 'ant design part1',
            description: 'Ant Design, a design language for background applications, is refined by Ant UED Team1.',
            status: "2",
            schedule: 60,
          }
        ]
      },
      {
        DateDay: '今天5',
        dataContent: [
          {
            time: '16:09',
            href: 'http://ant.design',
            title: 'ant design part1',
            description: 'Ant Design, a design language for background applications, is refined by Ant UED Team1.',
            status: "1",
            schedule: 40,
          }
        ]
      },

    ];
    // this.historyListData2 = this.historyListData2
  }
  // 我的好友
  getMyFriend() {
    this.friendListData = [
      {
        href: 'http://ant.design',
        title: 'ant design part1',
        avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        description: 'Ant Design, a design language for background applications, is refined by Ant UED Team1.',
      },
      {
        href: 'http://ant.design',
        title: 'ant design part2',
        avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        description: 'Ant Design, a design language for background applications, is refined by Ant UED Team2.',
      },
      {
        href: 'http://ant.design',
        title: 'ant design part3',
        avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        description: 'Ant Design, a design language for background applications, is refined by Ant UED Team3.',
      }
    ];
  }
  getMyPersonal() {
    console.log(this.validateForm, "66666");
    console.log(this.user, "66666");
    this.validateForm.patchValue(this.user);
  }
  // 基本资料
  submitForm(value: { userfname: string; comment: string; usergender: string; userbirthday: number; usermobile: string, userpasswd: string, confirm: string }): void {
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }
    const paramsFrom = {
      userfname: value.userfname,
      usergender: value.usergender.toString(),
      userbirthday: value.userbirthday * 1000,
      usermobile: value.usermobile,
      usefacultyId: this.user.facultyId,
      // majorId:
      // uclassId:
    }
    console.log(value);
    this.userService.edit(JSON.stringify(paramsFrom),
      (response) => {
        this.message.create("success", "保存成功");
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.userService, this.message);
        }
      });
  }


  validateConfirmPassword(): void {
    setTimeout(() => this.validateForm.controls.confirm.updateValueAndValidity());
  }

  userNameAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        if (control.value === 'JasonWood') {
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          observer.next(null);
        }
        observer.complete();
      }, 1000);
    });

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };



  indexChange(index) {
    this.page = index;
    this.getCourseList();
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    console.log(this.user.userfname, "user");

    this.getCourseList();
    this.tabs = [
      { tid: 0, content: '我的课程' },
      { tid: 1, content: '我的收藏' },
      { tid: 2, content: '我的评论' },
      { tid: 3, content: '历史记录' },
      { tid: 4, content: '我的好友' },
      { tid: 5, content: '资料修改' },
    ]
    this.sexData = [
      { id: 0, label: '女', value: '女' },
      { id: 1, label: '男', value: '男' },
    ]

    console.log(this.tabs);

  }

}
