/**
 * @fileoverview gRPC-Web generated client stub for pbs
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var common_pb = require('./common_pb.js')

var exam_score_pb = require('./exam_score_pb.js')

var score_standard_pb = require('./score_standard_pb.js')

var user_pb = require('./user_pb.js')
const proto = {};
proto.pbs = require('./practice_score_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.PracticeScoreServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.pbs.PracticeScoreServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ScoreReq,
 *   !proto.pbs.Result>}
 */
const methodDescriptor_PracticeScoreService_GenerateScore = new grpc.web.MethodDescriptor(
  '/pbs.PracticeScoreService/GenerateScore',
  grpc.web.MethodType.UNARY,
  proto.pbs.ScoreReq,
  common_pb.Result,
  /**
   * @param {!proto.pbs.ScoreReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Result.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ScoreReq,
 *   !proto.pbs.Result>}
 */
const methodInfo_PracticeScoreService_GenerateScore = new grpc.web.AbstractClientBase.MethodInfo(
  common_pb.Result,
  /**
   * @param {!proto.pbs.ScoreReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_pb.Result.deserializeBinary
);


/**
 * @param {!proto.pbs.ScoreReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.Result)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.Result>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PracticeScoreServiceClient.prototype.generateScore =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PracticeScoreService/GenerateScore',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GenerateScore,
      callback);
};


/**
 * @param {!proto.pbs.ScoreReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.Result>}
 *     A native promise that resolves to the response
 */
proto.pbs.PracticeScoreServicePromiseClient.prototype.generateScore =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PracticeScoreService/GenerateScore',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GenerateScore);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ScoreStandardId,
 *   !proto.pbs.PracticeScoreId>}
 */
const methodDescriptor_PracticeScoreService_CreatePractice = new grpc.web.MethodDescriptor(
  '/pbs.PracticeScoreService/CreatePractice',
  grpc.web.MethodType.UNARY,
  score_standard_pb.ScoreStandardId,
  proto.pbs.PracticeScoreId,
  /**
   * @param {!proto.pbs.ScoreStandardId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.PracticeScoreId.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ScoreStandardId,
 *   !proto.pbs.PracticeScoreId>}
 */
const methodInfo_PracticeScoreService_CreatePractice = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.PracticeScoreId,
  /**
   * @param {!proto.pbs.ScoreStandardId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.PracticeScoreId.deserializeBinary
);


/**
 * @param {!proto.pbs.ScoreStandardId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.PracticeScoreId)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.PracticeScoreId>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PracticeScoreServiceClient.prototype.createPractice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PracticeScoreService/CreatePractice',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_CreatePractice,
      callback);
};


/**
 * @param {!proto.pbs.ScoreStandardId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.PracticeScoreId>}
 *     A native promise that resolves to the response
 */
proto.pbs.PracticeScoreServicePromiseClient.prototype.createPractice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PracticeScoreService/CreatePractice',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_CreatePractice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.GetPracticeListReq,
 *   !proto.pbs.PracticeScores>}
 */
const methodDescriptor_PracticeScoreService_GetPracticeList = new grpc.web.MethodDescriptor(
  '/pbs.PracticeScoreService/GetPracticeList',
  grpc.web.MethodType.UNARY,
  proto.pbs.GetPracticeListReq,
  proto.pbs.PracticeScores,
  /**
   * @param {!proto.pbs.GetPracticeListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.PracticeScores.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.GetPracticeListReq,
 *   !proto.pbs.PracticeScores>}
 */
const methodInfo_PracticeScoreService_GetPracticeList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.PracticeScores,
  /**
   * @param {!proto.pbs.GetPracticeListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.PracticeScores.deserializeBinary
);


/**
 * @param {!proto.pbs.GetPracticeListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.PracticeScores)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.PracticeScores>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PracticeScoreServiceClient.prototype.getPracticeList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PracticeScoreService/GetPracticeList',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GetPracticeList,
      callback);
};


/**
 * @param {!proto.pbs.GetPracticeListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.PracticeScores>}
 *     A native promise that resolves to the response
 */
proto.pbs.PracticeScoreServicePromiseClient.prototype.getPracticeList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PracticeScoreService/GetPracticeList',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GetPracticeList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.PracticeScoreId,
 *   !proto.pbs.TotalPracticeScoreResp>}
 */
const methodDescriptor_PracticeScoreService_GetTotalPracticeScore = new grpc.web.MethodDescriptor(
  '/pbs.PracticeScoreService/GetTotalPracticeScore',
  grpc.web.MethodType.UNARY,
  proto.pbs.PracticeScoreId,
  proto.pbs.TotalPracticeScoreResp,
  /**
   * @param {!proto.pbs.PracticeScoreId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.TotalPracticeScoreResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.PracticeScoreId,
 *   !proto.pbs.TotalPracticeScoreResp>}
 */
const methodInfo_PracticeScoreService_GetTotalPracticeScore = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.TotalPracticeScoreResp,
  /**
   * @param {!proto.pbs.PracticeScoreId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.TotalPracticeScoreResp.deserializeBinary
);


/**
 * @param {!proto.pbs.PracticeScoreId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.TotalPracticeScoreResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.TotalPracticeScoreResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PracticeScoreServiceClient.prototype.getTotalPracticeScore =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PracticeScoreService/GetTotalPracticeScore',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GetTotalPracticeScore,
      callback);
};


/**
 * @param {!proto.pbs.PracticeScoreId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.TotalPracticeScoreResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.PracticeScoreServicePromiseClient.prototype.getTotalPracticeScore =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PracticeScoreService/GetTotalPracticeScore',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GetTotalPracticeScore);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.PracticeScoreId,
 *   !proto.pbs.MachineOperationResp>}
 */
const methodDescriptor_PracticeScoreService_GetPracticeMachineOperation = new grpc.web.MethodDescriptor(
  '/pbs.PracticeScoreService/GetPracticeMachineOperation',
  grpc.web.MethodType.UNARY,
  proto.pbs.PracticeScoreId,
  exam_score_pb.MachineOperationResp,
  /**
   * @param {!proto.pbs.PracticeScoreId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  exam_score_pb.MachineOperationResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.PracticeScoreId,
 *   !proto.pbs.MachineOperationResp>}
 */
const methodInfo_PracticeScoreService_GetPracticeMachineOperation = new grpc.web.AbstractClientBase.MethodInfo(
  exam_score_pb.MachineOperationResp,
  /**
   * @param {!proto.pbs.PracticeScoreId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  exam_score_pb.MachineOperationResp.deserializeBinary
);


/**
 * @param {!proto.pbs.PracticeScoreId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.MachineOperationResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.MachineOperationResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PracticeScoreServiceClient.prototype.getPracticeMachineOperation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PracticeScoreService/GetPracticeMachineOperation',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GetPracticeMachineOperation,
      callback);
};


/**
 * @param {!proto.pbs.PracticeScoreId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.MachineOperationResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.PracticeScoreServicePromiseClient.prototype.getPracticeMachineOperation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PracticeScoreService/GetPracticeMachineOperation',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GetPracticeMachineOperation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.PracticeScoreId,
 *   !proto.pbs.MachineParamResp>}
 */
const methodDescriptor_PracticeScoreService_GetPracticeMachineParam = new grpc.web.MethodDescriptor(
  '/pbs.PracticeScoreService/GetPracticeMachineParam',
  grpc.web.MethodType.UNARY,
  proto.pbs.PracticeScoreId,
  exam_score_pb.MachineParamResp,
  /**
   * @param {!proto.pbs.PracticeScoreId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  exam_score_pb.MachineParamResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.PracticeScoreId,
 *   !proto.pbs.MachineParamResp>}
 */
const methodInfo_PracticeScoreService_GetPracticeMachineParam = new grpc.web.AbstractClientBase.MethodInfo(
  exam_score_pb.MachineParamResp,
  /**
   * @param {!proto.pbs.PracticeScoreId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  exam_score_pb.MachineParamResp.deserializeBinary
);


/**
 * @param {!proto.pbs.PracticeScoreId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.MachineParamResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.MachineParamResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PracticeScoreServiceClient.prototype.getPracticeMachineParam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PracticeScoreService/GetPracticeMachineParam',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GetPracticeMachineParam,
      callback);
};


/**
 * @param {!proto.pbs.PracticeScoreId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.MachineParamResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.PracticeScoreServicePromiseClient.prototype.getPracticeMachineParam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PracticeScoreService/GetPracticeMachineParam',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GetPracticeMachineParam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.PracticeScoreId,
 *   !proto.pbs.OperateProcessResp>}
 */
const methodDescriptor_PracticeScoreService_GetPracticeOperateProcess = new grpc.web.MethodDescriptor(
  '/pbs.PracticeScoreService/GetPracticeOperateProcess',
  grpc.web.MethodType.UNARY,
  proto.pbs.PracticeScoreId,
  exam_score_pb.OperateProcessResp,
  /**
   * @param {!proto.pbs.PracticeScoreId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  exam_score_pb.OperateProcessResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.PracticeScoreId,
 *   !proto.pbs.OperateProcessResp>}
 */
const methodInfo_PracticeScoreService_GetPracticeOperateProcess = new grpc.web.AbstractClientBase.MethodInfo(
  exam_score_pb.OperateProcessResp,
  /**
   * @param {!proto.pbs.PracticeScoreId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  exam_score_pb.OperateProcessResp.deserializeBinary
);


/**
 * @param {!proto.pbs.PracticeScoreId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.OperateProcessResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.OperateProcessResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PracticeScoreServiceClient.prototype.getPracticeOperateProcess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PracticeScoreService/GetPracticeOperateProcess',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GetPracticeOperateProcess,
      callback);
};


/**
 * @param {!proto.pbs.PracticeScoreId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.OperateProcessResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.PracticeScoreServicePromiseClient.prototype.getPracticeOperateProcess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PracticeScoreService/GetPracticeOperateProcess',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GetPracticeOperateProcess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.ScoreStandardId,
 *   !proto.pbs.PracticeCutterAndBlankResp>}
 */
const methodDescriptor_PracticeScoreService_GetPracticeCutterAndBlank = new grpc.web.MethodDescriptor(
  '/pbs.PracticeScoreService/GetPracticeCutterAndBlank',
  grpc.web.MethodType.UNARY,
  score_standard_pb.ScoreStandardId,
  proto.pbs.PracticeCutterAndBlankResp,
  /**
   * @param {!proto.pbs.ScoreStandardId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.PracticeCutterAndBlankResp.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.ScoreStandardId,
 *   !proto.pbs.PracticeCutterAndBlankResp>}
 */
const methodInfo_PracticeScoreService_GetPracticeCutterAndBlank = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.PracticeCutterAndBlankResp,
  /**
   * @param {!proto.pbs.ScoreStandardId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.PracticeCutterAndBlankResp.deserializeBinary
);


/**
 * @param {!proto.pbs.ScoreStandardId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.PracticeCutterAndBlankResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.PracticeCutterAndBlankResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PracticeScoreServiceClient.prototype.getPracticeCutterAndBlank =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PracticeScoreService/GetPracticeCutterAndBlank',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GetPracticeCutterAndBlank,
      callback);
};


/**
 * @param {!proto.pbs.ScoreStandardId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.PracticeCutterAndBlankResp>}
 *     A native promise that resolves to the response
 */
proto.pbs.PracticeScoreServicePromiseClient.prototype.getPracticeCutterAndBlank =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PracticeScoreService/GetPracticeCutterAndBlank',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_GetPracticeCutterAndBlank);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pbs.PracticeListReq,
 *   !proto.pbs.PracticeScores>}
 */
const methodDescriptor_PracticeScoreService_PracticeList = new grpc.web.MethodDescriptor(
  '/pbs.PracticeScoreService/PracticeList',
  grpc.web.MethodType.UNARY,
  proto.pbs.PracticeListReq,
  proto.pbs.PracticeScores,
  /**
   * @param {!proto.pbs.PracticeListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.PracticeScores.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.pbs.PracticeListReq,
 *   !proto.pbs.PracticeScores>}
 */
const methodInfo_PracticeScoreService_PracticeList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.pbs.PracticeScores,
  /**
   * @param {!proto.pbs.PracticeListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pbs.PracticeScores.deserializeBinary
);


/**
 * @param {!proto.pbs.PracticeListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.pbs.PracticeScores)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pbs.PracticeScores>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pbs.PracticeScoreServiceClient.prototype.practiceList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pbs.PracticeScoreService/PracticeList',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_PracticeList,
      callback);
};


/**
 * @param {!proto.pbs.PracticeListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pbs.PracticeScores>}
 *     A native promise that resolves to the response
 */
proto.pbs.PracticeScoreServicePromiseClient.prototype.practiceList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pbs.PracticeScoreService/PracticeList',
      request,
      metadata || {},
      methodDescriptor_PracticeScoreService_PracticeList);
};


module.exports = proto.pbs;

