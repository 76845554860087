import { Injectable } from '@angular/core';
import { Service } from './service';
import { VenueServiceClient } from '../pbs/venue_grpc_web_pb';
import { Venues,Venue,VenueSearchForm } from '../pbs/venue_pb';
import { Empty } from '../pbs/common_pb';

@Injectable({
  providedIn: 'root'
})
export class VenueService extends Service {

  venueService: VenueServiceClient;

  constructor() { 
    super()
    this.venueService = new VenueServiceClient(this.url,null,null);
  }


  /**
   * @description 获取场次列表
   * @author Megan
   * @date 2019-09-15
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof VenueService
   */
  getVenueList(callback:Function=null,fail:Function=null) {
    this.initToken();
    let isOk = false;
    this.venueService.list(new Empty(),
      this.metadata, (err:Error, response: Venues) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response);
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }

  /**
   * @description 搜索场次列表
   * @author Megan
   * @date 2019-09-21
   * @param {*} start
   * @param {*} end
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof VenueService
   */
  searchList(start:any,end:any,callback:Function=null,fail:Function=null) {
    this.initToken();
    let isOk = false;
    let searchForm = new VenueSearchForm();
	searchForm.setStatus(-1);
    searchForm.setStart(start);
    searchForm.setEnd(end);
	
    this.venueService.search(
      searchForm,
      this.metadata, (err: Error, response: Venue) => {
        if (!isOk) { 
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
         
      }
    )  
  }

  /**
   * @description 查看场次
   * @author Megan
   * @date 2019-09-15
   * @param {String} venueid
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof VenueService
   */
  viewVenue(venueid:String,callback:Function,fail:Function=null) {
    this.initToken();
    let isOk = false;
    let venue = new Venue();
    venue.setVenueid(venueid);
    this.venueService.view(
      venue,
      this.metadata, (err: Error, response: Venue) => {
        if (!isOk) { 
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }    
      }
    )
  }


  /**
   * @description 编辑场次
   * @author Megan
   * @date 2019-09-15
   * @param {string} param
   * @param {Function} callback
   * @param {Function} [fail=null]
   * @memberof VenueService
   */
  editVenue(param: string,callback:Function,fail:Function=null) {
    this.initToken();
    let isOk = false;
    let ve = JSON.parse(param);
    let venue = new Venue().toObject();
    venue.venueid = ve.venueId;
    venue.venuecreator = ve.venueExaminee;
    this.venueService.edit(
      venue,
      this.metadata, (err: Error, response: Venue) => {
        if (!isOk) { 
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }
}
