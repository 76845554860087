import { Component, OnInit, OnDestroy } from '@angular/core';
import { NzMessageService, UploadFile, NzModalRef, NzModalService, NzStringTemplateOutletDirective } from 'ng-zorro-antd';
import { Observable, Observer } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
// import { VenueService } from 'src/app/services/venue.service';
import { ExamsService } from 'src/app/services/exams.service';
import { EventService } from 'src/app/event.service';
import { UploadService } from 'src/app/services/upload.service';
import { UploadReq, UploadResp } from 'src/app/pbs/upload_pb';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['../registration.component.styl','./step3.component.styl']
})
export class Step3Component implements OnInit,OnDestroy {

  ngOnInit() {
    this.user = this.examsService.getUser();//userfname
    this.user.userbirthday = this.user.userbirthday * 1000; //167875200
    this.user.usergender = this.user.usergender.toString();
  }

  loading = false;

  public user:any;
  private venueId:string;
  
  public degreeData = [
    {
      value: 1,
      name: "小学"
    },
    {
      value: 2,
      name: "初中"
    },
    {
      value: 3,
      name: "高中/中专"
    },
    {
      value: 4,
      name: "本科"
    },
    {
      value: 5,
      name: "硕士"
    },
    {
      value: 6,
      name: "博士"
    }
  ];
  public nationData = [
    {
      value: "汉",
      name: "汉族"
    },
    {
      value: "壮族",
      name: "壮族"
    },
    {
      value: "藏族",
      name: "藏族"
    },
    {
      value: "裕固族",
      name: "裕固族"
    },
    {
      value: "彝族",
      name: "彝族"
    },
    {
      value: "瑶族",
      name: "瑶族"
    },
    {
      value: "锡伯族",
      name: "锡伯族"
    },
    {
      value: "乌孜别克族",
      name: "乌孜别克族"
    },
    {
      value: "维吾尔族",
      name: "维吾尔族"
    },
    {
      value: "佤族",
      name: "佤族"
    },
    {
      value: "土家族",
      name: "土家族"
    },
    {
      value: "土族",
      name: "土族"
    },
    {
      value: "塔塔尔族",
      name: "塔塔尔族"
    },
    {
      value: "塔吉克族",
      name: "塔吉克族"
    },
    {
      value: "水族",
      name: "水族"
    },
    {
      value: "畲族",
      name: "畲族"
    },
    {
      value: "撒拉族",
      name: "撒拉族"
    },
    {
      value: "羌族",
      name: "羌族"
    },
    {
      value: "普米族",
      name: "普米族"
    },
    {
      value: "怒族",
      name: "怒族"
    },
    {
      value: "纳西族",
      name: "纳西族"
    },
    {
      value: "仫佬族",
      name: "仫佬族"
    },
    {
      value: "苗族",
      name: "苗族"
    },
    {
      value: "蒙古族",
      name: "蒙古族"
    },
    {
      value: "门巴族",
      name: "门巴族"
    },
    {
      value: "毛南族",
      name: "毛南族"
    },
    {
      value: "满族",
      name: "满族"
    },
    {
      value: "珞巴族",
      name: "珞巴族"
    },
    {
      value: "僳僳族",
      name: "僳僳族"
    },
    {
      value: "黎族",
      name: "黎族"
    },
    {
      value: "拉祜族",
      name: "拉祜族"
    },
    {
      value: "柯尔克孜族",
      name: "柯尔克孜族"
    },
    {
      value: "景颇族",
      name: "景颇族"
    },
    {
      value: "基诺族",
      name: "基诺族"
    },
    {
      value: "回族",
      name: "回族"
    },
    {
      value: "赫哲族",
      name: "赫哲族"
    },
    {
      value: "哈萨克族",
      name: "哈萨克族"
    },
    {
      value: "哈尼族",
      name: "哈尼族"
    },
    {
      value: "仡佬族",
      name: "仡佬族"
    },
    {
      value: "高山族",
      name: "高山族"
    },
    {
      value: "鄂温克族",
      name: "鄂温克族"
    },
    {
      value: "俄罗斯族",
      name: "俄罗斯族"
    },
    {
      value: "鄂伦春族",
      name: "鄂伦春族"
    },
    {
      value: "独龙族",
      name: "独龙族"
    },
    {
      value: "东乡族",
      name: "东乡族"
    },
    {
      value: "侗族",
      name: "侗族"
    },
    {
      value: "德昂族",
      name: "德昂族"
    },
    {
      value: "傣族",
      name: "傣族"
    },
    {
      value: "达斡尔族",
      name: "达斡尔族"
    },
    {
      value: "朝鲜族",
      name: "朝鲜族"
    },
    {
      value: "布依族",
      name: "布依族"
    },
    {
      value: "保安族",
      name: "保安族"
    },
    {
      value: "布朗族",
      name: "布朗族"
    },
    {
      value: "白族",
      name: "白族"
    },
    {
      value: "阿昌族",
      name: "阿昌族"
    }
  ];



  constructor(private message: NzMessageService, 
    private modalService: NzModalService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    private examsService: ExamsService,
	private uploadService: UploadService,
    private event: EventService) {
    activatedRoute.queryParams.subscribe(queryParams => {
      this.venueId = queryParams.venueId;
    })
  }

  beforeUpload = (file: File) => {
    let isFileRight = false;
    if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'){
      isFileRight = true;
    }
    if (!isFileRight) {
      console.log(file.type);
      this.message.error('请上传格式为jpg/jepg/png的图片');
      return false;
    }
    const isLt500K = (file.size / 1024) < 500;
    if (!isLt500K) {
      this.message.error('图片不能大于500k!');
      return false;
    }

    if (isFileRight && isLt500K) {
      return true;
    }
  };

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  private getBytes(img: File, callback: (img: Uint8Array) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(new Uint8Array(<ArrayBuffer>reader.result)));
    reader.readAsArrayBuffer(img)
  }

  onChange(et:any) {
    let file = et.target.files[0];
    if (this.beforeUpload(file)) {
      // this.getBase64(file, (img: string) => {
      //   this.user.useravatar = img;
      // })
	  this.getBytes(file, (img: Uint8Array) => {
	    this.uploadFile(img)
	  })
    }
  }
  
  uploadFile(bytes) {
    let uploadReq = new UploadReq()
    uploadReq.setFile(bytes)
    this.uploadService.upload(uploadReq,(data:UploadResp)=>{
  	  this.user.useravatar = environment.domainName + data.path;
  	  this.user["headimg"] = data.path;
    },(err:string)=>{
      this.message.error('图片上传服务器失败')
    })
  }

  commitRegistration(){
    let param = {
      venueId: this.venueId,
      venueExaminee: this.user
    }
    this.examsService.applayForm(JSON.stringify(param),
      (response) => {
        // console.log(response);
        const modal: NzModalRef = this.modalService.create({
          nzTitle: '完成提交',
          nzMaskClosable: false,
          nzContent: '<div class="modal-container">\
                        <div class="icon"><img src="/assets/img/ok.png" alt=""></div>\
                        <p>您报名成功了哦！</p>\
                      </div>',
          nzFooter: [
            {
              label: '查看结果',
              NzButtonShape: 'primary',
              onClick: () => {
                modal.destroy();
                this.event.sendVenueData(response.examvenue);
                localStorage.setItem("exam",JSON.stringify(response));
                this.router.navigate(['/registration/step4'],{ queryParams: { venueId: response.venueId } });
              }
            }
          ]
        });
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.examsService, this.message);
        }
      });
    
  }

  previousStep(){
    this.router.navigate(['/registration/step2'], { queryParams: { venueId: this.venueId } });
  }

  ngOnDestroy(){
    console.log('Destroy');
  }
  
  toRedirectApplyStep(step) {
  	  let url = ""
  	  if (step == 1) {
  		  url = '/registration/step1'
  	  } else if (step == 2) {
  		  url = '/registration/step2'
  	  } else if (step == 3) {
  		  url = '/registration/step3'
  	  } else if (step == 4) {
  		  url = '/registration/step4'
  	  }
	  this.router.navigate([url],{ queryParams: { venueId: this.venueId } });
  }
  
}
