import { Injectable } from '@angular/core';
import { Service } from './service';
import { UploadServiceClient } from '../pbs/upload_grpc_web_pb';
import { UploadReq, UploadResp } from '../pbs/upload_pb';
import { Error, Status } from 'grpc-web';

@Injectable({
  providedIn: 'root'
})

export class UploadService extends Service {
  Uploadclient: UploadServiceClient;

  constructor() {
    super()
    this.Uploadclient = new UploadServiceClient(this.url,null,null);
  }

  /**
   * @description 上传图片
   * @author Megan
   * @date 2019-09-15
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof UploadService
   */
  upload(req:UploadReq,callback:Function=null,fail:Function=null) {
    //this.request(this.client,'upload',req,callback,fail)
	this.initToken();
	let isOk = false;
	this.Uploadclient.upload(req,
	  this.metadata, (err: Error, response: UploadResp) => {
	    if (!isOk) {
	      if (!err && callback != null) {
	        isOk = true;
	        callback(response.toObject());
	      }
	      
	      if (err && fail != null) {
	        isOk = true;
	        fail(err.message);
	      }
	    } 
	  }
	)
  }
}
