import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'degreePipe'
})
export class DegreePipePipe implements PipeTransform {

  private degreeData = [
    {
      value: 1,
      name: "小学"
    },
    {
      value: 2,
      name: "初中"
    },
    {
      value: 3,
      name: "高中/中专"
    },
    {
      value: 4,
      name: "本科"
    },
    {
      value: 5,
      name: "硕士"
    },
    {
      value: 6,
      name: "博士"
    }
  ];
  transform(value: number): any {
    let name = "";
    this.degreeData.forEach((item, index) => {
        if (item.value == value) {
          name = item.name;
        }
    });
    return name;
  }

}
