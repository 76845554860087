import { Component, OnInit, ViewChild } from '@angular/core';
import { ElementRef, Renderer2} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import {Md5} from "ts-md5";
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-reviewpassword',
  templateUrl: './reviewpassword.component.html',
  styleUrls: ['./reviewpassword.component.styl']
})
export class ReviewpasswordComponent implements OnInit {

  private isCancel:boolean = true;
  public oldPwd: any = "";
  public newPwd: any = "";
  public confirmNewPwd: any = "";
  public isOldPwdValid: boolean = false;
  private isConfirmPwdValid: boolean = false;
  public errMessage: any = "";
  public errMessage2: any = "";
  public hasOldPasswd = false;

  @ViewChild('oldIpt', {static: false}) oldIpt:ElementRef;
  @ViewChild('confirmIpt', {static: true}) confirmIpt:ElementRef;

  constructor(private renderer2: Renderer2,
    private userService: UserService,
    private message: NzMessageService) { }

  checkOldPwd(oldPwd) {
    this.renderer2.removeClass(this.confirmIpt.nativeElement,'is-invalid');
    this.renderer2.removeClass(this.confirmIpt.nativeElement,'is-valid');
    this.userService.checkOldPwd(oldPwd,
      (response) => {
        this.isOldPwdValid = true;
        this.errMessage = "";
        this.renderer2.removeClass(this.oldIpt.nativeElement,'is-invalid');
        this.renderer2.addClass(this.oldIpt.nativeElement,'is-valid');
      },
      (fail) => {
        this.errMessage = decodeURI(fail);//"对不起，密码输入错误";
        this.renderer2.removeClass(this.oldIpt.nativeElement,'is-valid');
        this.renderer2.addClass(this.oldIpt.nativeElement,'is-invalid');
      });
  }
  checkComfirmPwd(confirmNewPwd){
    this.renderer2.removeClass(this.confirmIpt.nativeElement,'is-invalid');
    this.renderer2.removeClass(this.confirmIpt.nativeElement,'is-valid');
    if (confirmNewPwd == this.newPwd){
      this.errMessage2 = "";
      this.renderer2.addClass(this.confirmIpt.nativeElement,'is-valid');
    } else{
      this.errMessage2 = "对不起，两次密码输入不一致";
      this.renderer2.addClass(this.confirmIpt.nativeElement,'is-invalid');
    }
  }
	ngOnInit() {
		this.userService.getUserInfo(
		(response) => {
			console.log("userinfo", response)
			if (response.userpasswd != "") { //手机号注册的未设置密码的用户不需验证原密码
				this.hasOldPasswd = true
			} else {
				this.hasOldPasswd = false
				this.isOldPwdValid = true
			}
		},
		(fail) => {
			this.message.create("error",decodeURI(fail));
		});
	}

}
