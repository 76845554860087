import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamsService } from 'src/app/services/exams.service';
import { NzMessageService } from 'ng-zorro-antd';
import { EventService } from 'src/app/event.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.styl']
})
export class MainComponent implements OnInit {

  private type:any;
  public examId:string;
  public startTime:any;
  public endTime:any;
  public err: any;
  public scoreAll:number=0;
  constructor(private activatedRoute: ActivatedRoute,
    private examsService: ExamsService,
    private message: NzMessageService,
    private router: Router,
    private event: EventService) { 
    activatedRoute.queryParams.subscribe(queryParams => {
      this.type = queryParams.type;
      this.examId = queryParams.examId;
    })
  }


  private examData = {
    examid: "11",
    examscore: 90,
    examvenue: {
      venueid: "11-11",
      venuemajor: {
        majorid: "12345",
        majorname: "xxx"
      },
      venuestart: 1236548252,
      venuemaxcount: 30,
      venuepaper: {
        paperid: "11",
        paperdur: 30,//试卷时长
        paperquestionList: [
          {
            questionid: "1",
            questionname: "数控车床主要加工什么类型的零件（）",
            questiontype: 0,
            questionscores: 0,
            questionanswer: "A",
            questionexamanswerList: [],
            questioncorrect: false,
            questionanswerdesc: "",
            questionmedia: "",
            questionoptionArr:[],
            questionoptionMap: [],
            questionoptionList: [
            ]
          }
        ],
        paperscorestandard: {} //实操题
      },
      venueexamineeList: []
    },
    examexaminee:{
      userid:"111"
    },
    examsn: "123",
    examanswersList: [],
    examstatus: 2,
    examteacher: undefined,
    examdeleteat: 0,
    examreviewat: 1236548952,
    examupdateat: 1354654516,
    examcreateat: 1234554656
  }

  private questionData:any = [
    {
      typeId: 0,
      name: "单选题",
      iconPath: "/assets/img/s1.png",
      iconclass:'icon-danxuan',
      isCurrent: true,
      score: 0,
      questions: [],
      isShow: false
    },
    {
      typeId: 1,
      name: "多选题",
      iconPath: "/assets/img/m1.png",
      iconclass:'icon-duoxuan',
      isCurrent: false,
      score: 0,
      questions: [],
      isShow: false
    },
    {
      typeId: 2,
      name: "填空题",
      iconPath: "/assets/img/t1.png",
      iconclass:'icon-blanksfill',
      isCurrent: false,
      score: 0,
      questions: [],
      isShow: false
    },
    {
      typeId: 3,
      name: "标注题",
      iconPath: "/assets/img/t1.png",
      iconclass:'icon-biaozhu',
      isCurrent: false,
      score: 0,
      questions: [],
      isShow: false
    },
    {
      typeId: 4,
      name: "简答题",
      iconPath: "/assets/img/t1.png",
      iconclass:'icon-shortanswer',
      isCurrent: false,
      score: 0,
      questions: [],
      isShow: false
    },
    {
      typeId: 5,
      name: "实操试题",
      iconPath: "/assets/img/c1.png",
      iconclass:'icon-caozuo',
      isCurrent: false,
      score: 0,
      questions: [],
      isShow: false
    }
  ];

  ngOnInit() {
    
    setTimeout(()=>{
      let examVenue = localStorage.getItem("examVenue");
      let id  = examVenue ? JSON.parse(examVenue).examid : "";
      // 初始化试卷
      if (this.type){
        this.examsService.getResult(this.examId,
          (response) => {
            console.log("main查看试卷",response);
            // examscore
            // this.event.publish(response, null);
            this.initData(response,id);
          },
          (fail) => {
            this.message.create("error",decodeURI(fail));
            if (decodeURI(fail) == "token无效") {
              common.returnLogin(this.router, this.examsService, this.message);
            }
          })
        console.log(this.examId);
      } else {
		    localStorage.removeItem("exam")
        // let examVenue = localStorage.getItem("examVenue");

        this.examsService.startExam(
          (response) => {
            console.log("main试卷",response); 
            if (!examVenue) { 
              localStorage.setItem("examVenue",JSON.stringify(response)); 
              this.initData(response,id); 
              localStorage.setItem("questionData",JSON.stringify(this.questionData)); 
            } else { 
              let exam = JSON.parse(examVenue);
              if (id == response.examid) {
				  console.log("exam:"+examVenue);
                this.initData(exam,id); 
              } else {
                localStorage.removeItem("examVenue");
                localStorage.removeItem("questionData");
                localStorage.setItem("examVenue",JSON.stringify(response)); 
                this.initData(response,id); 
              }
              localStorage.setItem("questionData",JSON.stringify(this.questionData));
            } 
    
            // localStorage.setItem("examVenue",JSON.stringify(response));
            // this.initData(response);
            
          },
          (fail) => {
            this.message.create("error",decodeURI(fail));
            if (decodeURI(fail) == "token无效") {
              common.returnLogin(this.router, this.examsService, this.message);
            } else {
              this.router.navigate(['/entrance']);
            }
          }
        ) 
      }
    },100)

  }

  initData(response,id) {
    this.scoreAll = response.examscore;
    this.startTime = response.examvenue.venuestart * 1000;
    this.endTime = (response.examvenue.venuestart + response.examvenue.venuepaper.paperdur) * 1000 ;
    this.examData = response;
    this.examId = response.examid;

    let questData = null;
    if (this.examId == id) {
      questData = localStorage.getItem("questionData");
    }
	console.log("questData:"+questData)
    if (questData && !this.type) {
      this.questionData = JSON.parse(questData);
    } else {
      //试卷题型重组
      this.examData.examvenue.venuepaper.paperquestionList.forEach((item, i) => {
        this.examData.examanswersList[i] = this.examData.examanswersList[i] || [];
        item["index"] = i;
        item["isActive"] = false; 
        item["isFlag"] = false; //是否标记题
        item.questionexamanswerList = item.questionexamanswerList ? item.questionexamanswerList : [];
        
        item["answerType"] = 0;  //题目答案类型 0未答 1答对 2答错

        item["questionoptionList"] = []; //选择题的选项
        for (let k = 0; k < item.questionoptionMap.length; k++) {
          let listItem = {};
          listItem["id"] = item.questionoptionMap[k][0];
          listItem["value"] = item.questionoptionMap[k][1];
          item["questionoptionList"].push(listItem);
        }

        //questiontype题型 0单选 1多选 2填空 3标注 4简答 5实操
        switch (item.questiontype) {
          case 0:{
            if (!this.type) {
              if (item.questionexamanswerList.length != 0) {
                item["isActive"] = true;
              }
            }
            
            this.questionData[0].isShow = true;
            this.questionData[0].questions.push(item);
            this.questionData[0].score = this.questionData[0].score + item.questionscores;
            break;
          }
          case 1: {
            //根据考生答案来判断选择了哪些选项

            let ans = item.questionexamanswerList;

            if (!this.type) {
              if (ans.length != 0) {
                item["isActive"] = true;
              }
            }

            for (let i = 0; i < item["questionoptionList"].length; i++) {
              item["questionoptionList"][i]["isChecked"] = false;
              if (ans) {
                for (let j = 0; j < ans.length; j++) {
                  if(item["questionoptionList"][i].id == ans[j]) {
                    item["questionoptionList"][i]["isChecked"] = true;
                  }
                }
              }
            }
            this.questionData[1].isShow = true;
            this.questionData[1].questions.push(item);
            this.questionData[1].score = this.questionData[1].score + item.questionscores;
            break;
          }
          case 2: {
            let ans = item.questionexamanswerList;
            if (!this.type) {
              if (ans.length != 0) {
                item["isActive"] = true;
              }
            }
            this.questionData[2].isShow = true;
            item.questionoptionArr = common.replaceHtml(item.questionname,ans);
            this.questionData[2].questions.push(item);
            this.questionData[2].score = this.questionData[2].score + item.questionscores;
            break;
          }
          case 3:{
            let ans = item.questionexamanswerList;
            if (!this.type) {
              if (ans.length != 0) {
                item["isActive"] = true;
              }
            }
            this.questionData[3].isShow = true;
            this.questionData[3].questions.push(item);
            this.questionData[3].score = this.questionData[3].score + item.questionscores;
            break;
          }        
          case 4:{
            if (!this.type) {
              if (item.questionexamanswerList.length != 0) {
                item["isActive"] = true;
              }
            }
            this.questionData[4].isShow = true;
            this.questionData[4].questions.push(item);
            this.questionData[4].score = this.questionData[4].score + item.questionscores;
            break;
          }
          // case 5:{
          //   this.questionData[5].isShow = true;
          //   break;
          // }
          
          default:
            break;
        }

        //判断考生答案是否正确
        if (item.questioncorrect) {
          item["answerType"] = 1;
        } else {
          if (item.questionexamanswerList.length == 0) {
            item["answerType"] = 0;
          } else {
            item["answerType"] = 2;
          }
        }
      });
		console.log("initData.standardtype:"+response.examvenue.venuepaper.paperscorestandard.standardType);
      switch (response.examvenue.venuepaper.paperscorestandard.standardType){
              case 0: //车床
              case 1: //铣床
                // this.examData.examvenue.venuepaper.paperscorestandard
                this.questionData[5].questions.push(
                    {
                      questionid: "1",
                      questionname: "请仔细阅读以下图纸中的工件参数，然后开始加工出该工件。",
                      questiontype: 5,
                      questionscores: 0,
                      questionanswer: "",
                      questionexamanswerList: [],
                      questioncorrect: false,
                      questionanswerdesc: "",
                      questionmedia: response.examvenue.venuepaper.paperscorestandard.profileParam?response.examvenue.venuepaper.paperscorestandard.profileParam.profileImg:"",
                      questionoptionArr:[],
                      questionoptionMap: [],
                      questionoptionList: [
                      ]
                    }
                );
                break;
              case 2: //ma6标准
                // this.examData.examvenue.venuepaper.paperscorestandard
                this.questionData[5].questions.push(
                    {
                      questionid: "1",
                      questionname: "实验要求",
                      questiontype: 5,
                      questionscores: 0,
                      questionanswer: "",
                      questionexamanswerList: [],
                      questioncorrect: false,
                      questionanswerdesc: "",
                      questionmedia: response.examvenue.venuepaper.paperscorestandard.ma6ScoreStandard.experimentRequirements,
                      questionoptionArr:[],
                      questionoptionMap: [],
                      questionoptionList: [
                      ]
                    }
                );
                break;
            }

      let qstData  = [],qtIndex = 1;
      //判断题型是否存在
      for (let index = 0; index < this.questionData.length; index++) {
        if (this.questionData[index].isShow) {
          qstData.push(this.questionData[index]);
        }
      }
      if (!this.type) {
        qstData.push(this.questionData[5]);
      }
      for (let m = 0; m < qstData.length; m++) {
        qstData[m].questions.forEach((el, i)=> {
          el["qtIndex"] = qtIndex;
          if (this.type) {
            el["totalNum"] = this.examData.examvenue.venuepaper.paperquestionList.length;
          } else {
            el["totalNum"] = this.examData.examvenue.venuepaper.paperquestionList.length + 1;
          }
          
          qtIndex = qtIndex + 1;
        });
      }
      this.questionData  = qstData;
    }
    
    this.event.sendMenuData(this.questionData);
    this.event.sendExamStatusData(response.examstatus);
  }
}
