import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-print-template',
  templateUrl: './print-template.component.html',
  styleUrls: ['../registration/step4/step4.component.styl','./print-template.component.styl']
})
export class PrintTemplateComponent implements OnInit {

  public listOfData:any = [];

  constructor(private activatedRoute: ActivatedRoute,private router: Router) {
    activatedRoute.queryParams.subscribe(queryParams => {
      this.listOfData = JSON.parse(queryParams.listOfData);
    })
  }

  ngAfterViewInit(){
    setTimeout(()=>{
        window.print();
        this.router.navigate(['/registration/step4']);
    },100)
    
  }

  ngOnInit() {
  }

}
