import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/event.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd';
import { CourseService } from 'src/app/services/course.service';

declare var ckplayer;
declare var $: any;

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.styl']
})
export class DetailComponent implements OnInit {

  public detailData: any = {
    courseCreator: {
      userfname: "",
      userid: "",
      useravatar: ""
    },
    courseFile: "",
    courseName: "",
    courseDifficulty: 0,
    courseIntro: "",
    courseCoverImg: "",
    courseDesc: "",
    courseType: 0
  };
  public player: any;
  public listData: any = [];
  public commentData: any = [];
  public difficultyData = [
    {
      value: 0,
      name: "入门",
      active: false
    },
    {
      value: 1,
      name: "初级",
      active: false
    },
    {
      value: 2,
      name: "中级",
      active: false
    },
    {
      value: 3,
      name: "高级",
      active: false
    }
  ];

  public courseId: string;

  public host: string = environment.domainName;

  constructor(private event: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private message: NzMessageService,
    private courseService: CourseService) {
    activatedRoute.queryParams.subscribe(queryParams => {
      this.courseId = queryParams.courseId;
    })
  }

  videoPlayer() {
    var videoObject = {
      container: '#video',//“#”代表容器的ID，“.”或“”代表容器的class
      variable: 'player',//该属性必需设置，值等于下面的new chplayer()的对象
      autoplay: false,//自动播放
      live: false,
      poster: this.detailData.courseCoverImg ? this.host + this.detailData.courseCoverImg : "",
      // video:'https://f.video.weibocdn.com/gW3pb95mlx07FzNDgLck01041203QliF0E020.mp4?label=mp4_hd&template=852x480.25.0&trans_finger=62b30a3f061b162e421008955c73f536&ori=0&ps=1CwnkDw1GXwCQx&Expires=1597286707&ssig=NeD6gaO0GQ&KID=unistore,video'//视频地址
      video: this.host + this.detailData.courseFile
    };
    this.player = new ckplayer(videoObject);
  }

  onFav(item: any) {
    console.log(item);

    let type = 1; //0取消收藏 1收藏
    if (item.hasCollect) {
      type = 0
    } else {
      type = 1;
    }
    console.log(type);

    event.stopPropagation();
    this.courseService.setCollect(item.id, type,
      (response) => {
        console.log(response, "response");
        console.log(this.detailData, "detailData");
        if (type == 0) {
          this.detailData.hasCollect = false;
        } else {
          this.detailData.hasCollect = true;
        }
        this.detailData.collectNums = response.collectNum
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.courseService, this.message);
        }
      }
    );
  }

  onClick(userId: string) {
    this.router.navigate(['/class/teacher-main'], { queryParams: { userid: userId } });
  }

  ngOnInit() {
    this.courseService.getDetail(this.courseId,
      (response) => {
        this.detailData = response;
        // this.detailData.courseDifficulty = this.detailData 
        this.detailData.courseDifficulty = this.difficultyData.filter(item => item.value === this.detailData.courseDifficulty)[0].name;
        console.log(this.detailData);
        this.videoPlayer();
        // console.log(response.dataList);
      },
      (fail) => {
        this.message.create("error", decodeURI(fail));
        if (decodeURI(fail) == "token无效") {
          common.returnLogin(this.router, this.courseService, this.message);
        }
      }
    );

    this.commentData = {
      author: 'Han Solo',
      avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
      content:
        'We supply a series of design principles, practical patterns and high quality design resources' +
        '(Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
      children: [
        {
          author: 'Han Solo',
          avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
          content:
            'We supply a series of design principles, practical patterns and high quality design resources' +
            '(Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
          children: [
            {
              author: 'Han Solo',
              avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
              content:
                'We supply a series of design principles, practical patterns and high quality design resources' +
                '(Sketch and Axure), to help people create their product prototypes beautifully and efficiently.'
            },
            {
              author: 'Han Solo',
              avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
              content:
                'We supply a series of design principles, practical patterns and high quality design resources' +
                '(Sketch and Axure), to help people create their product prototypes beautifully and efficiently.'
            }
          ]
        }
      ]
    }
  }

  ngAfterViewInit() {
    setTimeout(function () {
      $(".class-main").height($(".main-content").height() - 120);
    }, 500);
  }
}
