import { Injectable } from '@angular/core';
import { Service } from './service';
import { CourseServiceClient } from '../pbs/course_grpc_web_pb';
import { Courses,CourseId,CourseListReq,CollectReq,CollectResp,MyCollectReq} from '../pbs/course_pb';
import { Empty } from '../pbs/common_pb';
import { isNull } from 'util';

@Injectable({
  providedIn: 'root'
})
export class CourseService extends Service {

  courseService: CourseServiceClient;

  constructor() { 
    super()
    this.courseService = new CourseServiceClient(this.url, null, null);
  }

  /**
   * @description 获取课程列表
   * @author Megan
   * @date 2020-08-21
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof MajorService
   */
  getList(param: string,callback:Function=null,fail:Function=null) {
    this.initToken();
    let isOk = false;
    let parm = JSON.parse(param);
    let courseListReq = new CourseListReq();
    if (parm.categoryId != null) {
      courseListReq.setCourseCategoryId(parm.categoryId);
    }
    courseListReq.setCourseDifficulty(parm.courseDifficulty);
    if (parm.creatorId != null) {
      courseListReq.setCreatorId(parm.creatorId);
    }
    courseListReq.setKeyword(parm.keyword);
    courseListReq.setPage(parm.page);
    courseListReq.setPageSize(parm.pageSize);
    this.courseService.list(courseListReq,
      this.metadata, (err:Error, response: Courses) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }
  
  /**
   * @description 获取我的收藏课程列表
   * @author Megan
   * @date 2020-08-21
   * @param {Function} [callback=null]
   * @param {Function} [fail=null]
   * @memberof MajorService
   */
  getMyCollect(param: string,callback:Function=null,fail:Function=null) {
	this.initToken();
	let isOk = false;
	let parm = JSON.parse(param);
	let myCollectReq = new MyCollectReq();
	myCollectReq.setPage(parm.page);
	myCollectReq.setPageSize(parm.pageSize);
	this.courseService.myCollect(myCollectReq,
	  this.metadata, (err:Error, response: Courses) => {
	    if (!isOk) {
	      if (!err && callback != null) {
	        isOk = true;
	        callback(response.toObject());
	      }
	      
	      if (err && fail != null) {
	        isOk = true;
	        fail(err.message);
	      }
	    }
	  }
	)
  }
  
  getDetail(courseid:String,callback:Function=null,fail:Function=null){
    this.initToken();
    let isOk = false;
    let courseId =  new CourseId();
    courseId.setId(courseid);
    this.courseService.detail(courseId,
      this.metadata, (err:Error, response: Courses) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }

  setCollect(courseid:String,type:number,callback:Function=null,fail:Function=null){
    this.initToken();
    let isOk = false;
    let collectReq = new CollectReq();
    collectReq.setId(courseid);
    collectReq.setType(type);
    this.courseService.collect(collectReq,
      this.metadata, (err:Error, response:CollectResp) => {
        if (!isOk) {
          if (!err && callback != null) {
            isOk = true;
            callback(response.toObject());
          }
          
          if (err && fail != null) {
            isOk = true;
            fail(err.message);
          }
        }
      }
    )
  }

}
